/* Widget - Widget Image */

.widget-img {
	overflow: hidden;
	width: 40px;
	height: 40px;
	line-height: 40px;
	background-repeat: no-repeat;
	background-size: cover;
	background-position: center;
}
.widget-img.widget-img-xl {
	width: 80px;
	height: 80px;
	line-height: 80px;
}
.widget-img.widget-img-lg {
	width: 60px;
	height: 60px;
	line-height: 60px;
}
.widget-img.widget-img-sm {
	width: 30px;
	height: 30px;
	line-height: 30px;
}
.widget-img.widget-img-xs {
	width: 20px;
	height: 20px;
	line-height: 20px;
}