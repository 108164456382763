/* Mixins */

@mixin clearfix() {
	&:before,
	&:after {
		content: '';
		display: table;
		clear: both;
	}
}
@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
          border-radius: $radius;
}
@mixin box-shadow($shadow) {
  -webkit-box-shadow: $shadow;
     -moz-box-shadow: $shadow;
          box-shadow: $shadow;
}
@mixin opacity($opacity) {
    opacity: $opacity;
}
@mixin transition($transition) {
    -webkit-transition: $transition;
       -moz-transition: $transition;
        -ms-transition: $transition;
         -o-transition: $transition;
            transition: $transition;
}
@mixin animation($animation) {
    -webkit-animation: $animation;
       -moz-animation: $animation;
            animation: $animation;
}
@mixin generate-button-styling($buttonClassName, $defaultColor, $textColor, $hoverColor) {
    .btn.#{$buttonClassName},
	.btn.#{$buttonClassName}.disabled,
	.btn.#{$buttonClassName}.disabled:hover,
	.btn.#{$buttonClassName}.disabled:focus,
	.btn.#{$buttonClassName}[disabled],
	.btn.#{$buttonClassName}[disabled]:hover,
	.btn.#{$buttonClassName}[disabled]:focus {
        color: $textColor;
        background: $defaultColor;
        border-color: $defaultColor;
    }
	.#{$buttonClassName}:hover, 
	.#{$buttonClassName}:focus, 
	.#{$buttonClassName}:active, 
	.#{$buttonClassName}.active, 
	.#{$buttonClassName}.active.focus, 
	.#{$buttonClassName}.active:focus, 
	.#{$buttonClassName}.active:hover, 
	.#{$buttonClassName}:active.focus, 
	.#{$buttonClassName}:active:focus, 
	.#{$buttonClassName}:active:hover,
	.show > .#{$buttonClassName}.dropdown-toggle,
	.open > .dropdown-toggle.#{$buttonClassName},
	.open > .dropdown-toggle.#{$buttonClassName}:hover,
	.open > .dropdown-toggle.#{$buttonClassName}:focus,
	.#{$buttonClassName}:not(:disabled):not(.disabled).active, 
	.#{$buttonClassName}:not(:disabled):not(.disabled):active {
		background: $hoverColor;
		border-color: $hoverColor;
	}
	.input-group-append .btn.#{$buttonClassName},
	.btn-group .btn.#{$buttonClassName}:not(.active) + .btn.#{$buttonClassName},
	.input-group-btn .btn.#{$buttonClassName}:not(.active) + .btn.#{$buttonClassName} {
		border-left-color: $hoverColor;
	}
	.input-group-prepend .btn.#{$buttonClassName} {
		border-right-color: $hoverColor;
	}
}