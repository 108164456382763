/* Plugin - Tagit */

.tagit.ui-widget {
    border: 1px solid #ccc !important;
    background: #fff !important;
    padding: 3px 12px 2px !important;
    margin-bottom: 0 !important;
}
ul.tagit input[type="text"] {
    background: #fff !important;
    width: auto !important;
}
ul.tagit li.tagit-choice-editable {
    background: $silver_darker !important;
    border: none !important;
    color: #fff !important;
    border-radius: 3px !important;
    padding: 2px 24px 3px 7px !important;
}
.tagit .ui-icon-close {
    background: none !important;
    height: 22px !important;
    width: 16px !important;
    text-indent: 0 !important;
}
.tagit.white .ui-icon-close:before {
    color: $black_darker;
}
.tagit .ui-icon-close:before {
    content: '\f00d';
    font-size: 10px;
    color: #fff;
}
ul.tagit.inverse li.tagit-choice-editable { background: $black !important; }
ul.tagit.white li.tagit-choice-editable { background: #fff !important; border: 1px solid #ddd !important; color: $black_darker !important; }
ul.tagit.primary li.tagit-choice-editable { background: $blue !important; }
ul.tagit.info li.tagit-choice-editable { background: $aqua !important; }
ul.tagit.success li.tagit-choice-editable { background: $teal !important; }
ul.tagit.warning li.tagit-choice-editable { background: $orange !important; }
ul.tagit.danger li.tagit-choice-editable { background: $red !important; }
ul.tagit li.tagit-choice .tagit-close {
    right: 2px !important;
    line-height: 22px !important;
    text-align: center !important;
    top: 0 !important;
    margin: 0 !important;
}
ul.tagit li.tagit-choice .tagit-close:hover,
ul.tagit li.tagit-choice .tagit-close:focus {
    text-decoration: none !important;
}
ul.tagit li.tagit-choice .tagit-close:hover .ui-icon-close:before,
ul.tagit li.tagit-choice .tagit-close:focus .ui-icon-close:before {
    color: #666;
    color: rgba(0,0,0,0.4);
}
ul.tagit.inverse li.tagit-choice .tagit-close:hover .ui-icon-close:before,
ul.tagit.inverse li.tagit-choice .tagit-close:focus .ui-icon-close:before {
    color: #666;
    color: rgba(255,255,255,0.4);
}