/* Plugin - Summernote */

.note-editor.note-frame {
    border: none !important;
    margin: 0 !important;
    background: none;
}
.note-popover.popover {
	display: none;
}
.note-popover .popover-content, .panel-heading.note-toolbar {
    padding: 10px 15px !important;
    border-radius: 0;
    background: #222;
}
.note-editor.note-frame .note-placeholder,
.note-editor.note-frame .note-editing-area .note-editable {
	padding: 15px !important;
}
.note-toolbar.panel-heading .btn-group .btn {
	margin-top: 0 !important;
}
.note-toolbar .dropdown-toggle:after {
	display: none !important;
}
.note-btn-group .dropdown-menu .btn-group {
	display: inline-block;
}