/* Component - Inverse List Group Item */

.list-group-item {
	border-color: #e2e7eb;
}
.list-group-item.list-group-item-inverse {
    background: $black;
    border-color: $black_darker;
    color: #fff;
}
.list-group-item.list-group-item-inverse:hover, 
.list-group-item.list-group-item-inverse:focus {
    color: #fff;
    background: $black;
}
.list-group-item.list-group-item-inverse .label-inverse, 
.list-group-item.list-group-item-inverse .badge-inverse {
    background: $black;
    background: rgba(0,0,0,0.4);
}