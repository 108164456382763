/* Plugin - Bootstrap Calendar */

.bootstrap-calendar .calendar {
    background: #fff;
    border: none;
    border-radius: 0;
    padding: 10px 0;
}
.bootstrap-calendar .calendar a {
    line-height: 24px;
}
.bootstrap-calendar .calendar td {
    border: none !important;
}
.bootstrap-calendar .calendar .table td {
    padding: 3px 5px;
}
.bootstrap-calendar .calendar .week_days td {
    background: none;
    color: #a9acae;
}
.bootstrap-calendar .calendar .visualmonthyear {
    color: $black;
}
.bootstrap-calendar .calendar .table.header {
    margin-bottom: 5px;
    position: relative;
    z-index: 10;
}
.bootstrap-calendar .calendar .table.header td {
    color: #707478;
    vertical-align: middle;
}
.bootstrap-calendar .calendar .table.header .year {
    width: 72%;
}
.bootstrap-calendar .calendar .table.header td.year {
    font-size: 18px;
}
.bootstrap-calendar .calendar .icon-arrow-right,
.bootstrap-calendar .calendar .icon-arrow-left { 
    cursor: pointer;
    display: inline-block;
    font-size: 14px;
}
.bootstrap-calendar .calendar .icon-arrow-right:hover,
.bootstrap-calendar .calendar .icon-arrow-left:hover,
.bootstrap-calendar .calendar .icon-arrow-right:focus,
.bootstrap-calendar .calendar .icon-arrow-left:focus {
    color: $black;
}
.bootstrap-calendar .calendar .icon-arrow-left:before {
    content: '\f060';
}
.bootstrap-calendar .calendar .icon-arrow-right:before {
    content: '\f061';
}
.bootstrap-calendar .calendar .table {
    margin-bottom: 0;
}
.bootstrap-calendar .calendar .event a {
    display: block;
    border-radius: 30px;
    background: #fff;
    color: #fff;
    margin: 0 auto;
}
.bootstrap-calendar .calendar .event .popover a {
    color: #428bca;
}
.bootstrap-calendar .calendar .event .popover a:hover, 
.bootstrap-calendar .calendar .event .popover a:focus {
    color: #2a6496;
    text-decoration: underline;
}
.bootstrap-calendar .calendar .event,
.bootstrap-calendar .calendar .event:hover,
.bootstrap-calendar .calendar .event:focus,
.bootstrap-calendar .calendar .event a:hover,
.bootstrap-calendar .calendar .event a:focus {
    text-shadow: none;
    color: $black;
    box-shadow: none;
    font-weight: normal;
}
.bootstrap-calendar .calendar .event a:hover,
.bootstrap-calendar .calendar .event a:focus {
    color: #fff;
    opacity: 0.8;
}
.bootstrap-calendar .calendar .event,
.bootstrap-calendar .calendar .event:hover,
.bootstrap-calendar .calendar .event:focus {
    background: none;
    text-align: center;
}
.bootstrap-calendar .calendar .fa-circle {
    position: absolute;
    right: 0;
    top: 0;
}