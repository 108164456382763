/* Component - Alert */

.alert {
    border: none;
    padding: 15px;
}
.alert.alert-success { background: #bfe5e1; color: #004b44; }
.alert.alert-info { background: #bfeef4; color: #005e6a; }
.alert.alert-danger { background: #fcd0cd; color: #7a221b; }
.alert.alert-warning { background: #ffe5bf; color: #804c00; }
.alert.alert-primary { background: #c2ddf7; color: #114b7a; }
.alert.alert-dark { background: #c7c7c7; color: #111111; }
.alert.alert-secondary { background: #e7e7e7; color: #4f4f4f; }
.alert.alert-light { background: #fff; color: #666; }
.alert.alert-purple { background: #e6c9eb; color: #4e1458; }
.alert.alert-yellow { background: #ffface; color: #806d00; }
.alert.alert-lime { background: #e2f0d2; color: #466225; }
.alert.alert-indigo { background: #c5cae9; color: #20295b; }
.alert.alert-pink { background: #f8bbd0; color: #750f32; }
.alert.alert-green { background: #c9e7ca; color: #265828; }

.alert.alert-success .alert-link { color: #002622; }
.alert.alert-info .alert-link { color: #002f35; }
.alert.alert-danger .alert-link { color: #3d110e; }
.alert.alert-warning .alert-link { color: #402600; }
.alert.alert-primary .alert-link { color: #08263d; }
.alert.alert-dark .alert-link { color: #080808; }
.alert.alert-secondary .alert-link { color: #282828; }
.alert.alert-light .alert-link { color: $black; }
.alert.alert-purple .alert-link { color: #270a2c; }
.alert.alert-yellow .alert-link { color: #403600; }
.alert.alert-lime .alert-link { color: #233113; }
.alert.alert-indigo .alert-link { color: #131837; }
.alert.alert-pink .alert-link { color: #46091e; }
.alert.alert-green .alert-link { color: #173518; }