/* Plugin - Select 2 */

.select2-dropdown {
    border: none !important;
    box-shadow: 0 10px 20px 0px rgba(0, 0, 0, 0.15) !important;
    z-index: 1020 !important;
}
.select2-dropdown.select2-dropdown--above {
    box-shadow: 0 -10px 20px 0px rgba(0, 0, 0, 0.15) !important;
}
.select2-container .select2-selection--single:focus {
    outline: 0 !important;
}
.select2.select2-container--focus .select2-selection--single:focus {
	border-color: #5db0ff;
    outline: 0 !important;
    box-shadow: 0 0 0 0.125rem rgba(52, 142, 227, 0.3);
}
.select2-container--default .select2-results__option--highlighted[aria-selected] {
    background: $blue !important;
}
.select2-container .select2-selection--single,
.select2-container--default .select2-selection--multiple {
    min-height: 34px !important;
    border-color: #e2e7eb !important;
    outline: none !important;
}
.select2-container .select2-selection--single .select2-selection__rendered {
    padding-left: 12px !important;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    line-height: 34px !important;
    font-size: 13px;
}
.select2-container--default .select2-selection--single .select2-selection__arrow {
    right: 5px !important;
    height: 32px !important;
}
.select2-container--default .select2-selection--single .select2-selection__arrow b {
	border-width: 4px !important;
	border-color: #2e353c transparent transparent transparent !important;
}
.select2-container--default.select2-container--open .select2-selection--single .select2-selection__arrow b {
	border-color: transparent transparent #2e353c transparent !important;
	margin-top: -6px;
}
.select2-container--default .select2-results__group {
    color: $black_darker !important;
}
.select2-container--default .select2-search--inline .select2-search__field {
    height: 23px;
    padding-left: 5px;
    border-color: #ccc !important;
}
.select2-container--default .select2-search--dropdown .select2-search__field {
    padding: 6px 12px !important;
    height: 34px;
    border-color: #ccc !important;
}
.select2-container--default.select2-container--focus .select2-selection--multiple,
.select2-container--default .select2-search--dropdown .select2-search__field:focus {
    border-color: #5db0ff !important;
    outline: 0 !important;
    box-shadow: 0 0 0 0.125rem rgba(52, 142, 227, 0.3) !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
    margin-top: 6px !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered {
	padding: 0 12px !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__choice {
	border: none !important;
	margin-right: 8px !important;
	padding: 2px 6px !important;
}