/* Predefined Classes */

.row { margin-left: -10px; margin-right: -10px; }
.row > [class^="col-"] {  padding-left: 10px; padding-right: 10px; }
.row.row-space-0 { margin-left: 0; margin-right: 0; }
.row.row-space-2 { margin-left: -1px; margin-right: -1px; }
.row.row-space-4 { margin-left: -2px; margin-right: -2px; }
.row.row-space-6 { margin-left: -3px; margin-right: -3px; }
.row.row-space-8 { margin-left: -4px; margin-right: -4px; }
.row.row-space-10 { margin-left: -5px; margin-right: -5px; }
.row.row-space-12 { margin-left: -6px; margin-right: -6px; }
.row.row-space-14 { margin-left: -7px; margin-right: -7px; }
.row.row-space-16 { margin-left: -8px; margin-right: -8px; }
.row.row-space-18 { margin-left: -9px; margin-right: -9px; }
.row.row-space-20 { margin-left: -10px; margin-right: -10px; }
.row.row-space-22 { margin-left: -11px; margin-right: -11px; }
.row.row-space-24 { margin-left: -12px; margin-right: -12px; }
.row.row-space-26 { margin-left: -13px; margin-right: -13px; }
.row.row-space-28 { margin-left: -14px; margin-right: -14px; }
.row.row-space-30 { margin-left: -15px; margin-right: -15px; }
.row.row-space-0 > [class^="col-"] {  padding-left: 0; padding-right: 0; }
.row.row-space-2 > [class^="col-"] {  padding-left: 1px; padding-right: 1px; }
.row.row-space-4 > [class^="col-"] {  padding-left: 2px; padding-right: 2px; }
.row.row-space-6 > [class^="col-"] {  padding-left: 3px; padding-right: 3px; }
.row.row-space-8 > [class^="col-"] {  padding-left: 4px; padding-right: 4px; }
.row.row-space-10 > [class^="col-"] {  padding-left: 5px; padding-right: 5px; }
.row.row-space-12 > [class^="col-"] {  padding-left: 6px; padding-right: 6px; }
.row.row-space-14 > [class^="col-"] {  padding-left: 7px; padding-right: 7px; }
.row.row-space-16 > [class^="col-"] {  padding-left: 8px; padding-right: 8px; }
.row.row-space-18 > [class^="col-"] {  padding-left: 9px; padding-right: 9px; }
.row.row-space-20 > [class^="col-"] {  padding-left: 10px; padding-right: 10px; }
.row.row-space-22 > [class^="col-"] {  padding-left: 11px; padding-right: 11px; }
.row.row-space-24 > [class^="col-"] {  padding-left: 12px; padding-right: 12px; }
.row.row-space-26 > [class^="col-"] {  padding-left: 13px; padding-right: 13px; }
.row.row-space-28 > [class^="col-"] {  padding-left: 14px; padding-right: 14px; }
.row.row-space-30 > [class^="col-"] {  padding-left: 15px; padding-right: 15px; }

.overflow-auto { overflow: auto !important; }
.overflow-hidden { overflow: hidden !important; }
.overflow-visible { overflow: visible !important; }
.overflow-scroll { overflow: scroll !important; }
.overflow-x-hidden { overflow-x: hidden !important; }
.overflow-x-visible { overflow-x: visible !important; }
.overflow-x-scroll { overflow-x: scroll !important; }
.overflow-y-hidden { overflow-y: hidden !important; }
.overflow-y-visible { overflow-y: visible !important; }
.overflow-y-scroll { overflow-y: scroll !important; }

.m-auto { margin: 0 auto !important; }
.m-0 { margin: 0px !important; }
.m-1 { margin: 1px !important; }
.m-2 { margin: 2px !important; }
.m-3 { margin: 3px !important; }
.m-4 { margin: 4px !important; }
.m-5 { margin: 5px !important; }
.m-10 { margin: 10px !important; }
.m-15 { margin: 15px !important; }
.m-20 { margin: 20px !important; }
.m-25 { margin: 25px !important; }
.m-30 { margin: 30px !important; }
.m-35 { margin: 35px !important; }
.m-40 { margin: 40px !important; }

.m-t-0 { margin-top: 0px !important; }
.m-t-1 { margin-top: 1px !important; }
.m-t-2 { margin-top: 2px !important; }
.m-t-3 { margin-top: 3px !important; }
.m-t-4 { margin-top: 4px !important; }
.m-t-5 { margin-top: 5px !important; }
.m-t-10 { margin-top: 10px !important; }
.m-t-15 { margin-top: 15px !important; }
.m-t-20 { margin-top: 20px !important; }
.m-t-25 { margin-top: 25px !important; }
.m-t-30 { margin-top: 30px !important; }
.m-t-35 { margin-top: 35px !important; }
.m-t-40 { margin-top: 40px !important; }

.m-r-0 { margin-right: 0px !important; }
.m-r-1 { margin-right: 1px !important; }
.m-r-2 { margin-right: 2px !important; }
.m-r-3 { margin-right: 3px !important; }
.m-r-4 { margin-right: 4px !important; }
.m-r-5 { margin-right: 5px !important; }
.m-r-10 { margin-right: 10px !important; }
.m-r-15 { margin-right: 15px !important; }
.m-r-20 { margin-right: 20px !important; }
.m-r-25 { margin-right: 25px !important; }
.m-r-30 { margin-right: 30px !important; }
.m-r-35 { margin-right: 35px !important; }
.m-r-40 { margin-right: 40px !important; }

.m-b-0 { margin-bottom: 0px !important; }
.m-b-1 { margin-bottom: 1px !important; }
.m-b-2 { margin-bottom: 2px !important; }
.m-b-3 { margin-bottom: 3px !important; }
.m-b-4 { margin-bottom: 4px !important; }
.m-b-5 { margin-bottom: 5px !important; }
.m-b-10 { margin-bottom: 10px !important; }
.m-b-15 { margin-bottom: 15px !important; }
.m-b-20 { margin-bottom: 20px !important; }
.m-b-25 { margin-bottom: 25px !important; }
.m-b-30 { margin-bottom: 30px !important; }
.m-b-35 { margin-bottom: 35px !important; }
.m-b-40 { margin-bottom: 40px !important; }

.m-l-0 { margin-left: 0px !important; }
.m-l-1 { margin-left: 1px !important; }
.m-l-2 { margin-left: 2px !important; }
.m-l-3 { margin-left: 3px !important; }
.m-l-4 { margin-left: 4px !important; }
.m-l-5 { margin-left: 5px !important; }
.m-l-10 { margin-left: 10px !important; }
.m-l-15 { margin-left: 15px !important; }
.m-l-20 { margin-left: 20px !important; }
.m-l-25 { margin-left: 25px !important; }
.m-l-30 { margin-left: 30px !important; }
.m-l-35 { margin-left: 35px !important; }
.m-l-40 { margin-left: 40px !important; }

.p-0 { padding: 0px !important; }
.p-1 { padding: 1px !important; }
.p-2 { padding: 2px !important; }
.p-3 { padding: 3px !important; }
.p-4 { padding: 4px !important; }
.p-5 { padding: 5px !important; }
.p-10 { padding: 10px !important; }
.p-15 { padding: 15px !important; }
.wrapper { padding: 15px; }
.p-20 { padding: 20px !important; }
.p-25 { padding: 25px !important; }
.p-30 { padding: 30px !important; }
.p-35 { padding: 35px !important; }
.p-40 { padding: 40px !important; }

.p-t-0 { padding-top: 0px !important; }
.p-t-1 { padding-top: 1px !important; }
.p-t-2 { padding-top: 2px !important; }
.p-t-3 { padding-top: 3px !important; }
.p-t-4 { padding-top: 4px !important; }
.p-t-5 { padding-top: 5px !important; }
.p-t-10 { padding-top: 10px !important; }
.p-t-15 { padding-top: 15px !important; }
.p-t-20 { padding-top: 20px !important; }
.p-t-25 { padding-top: 25px !important; }
.p-t-30 { padding-top: 30px !important; }
.p-t-35 { padding-top: 35px !important; }
.p-t-40 { padding-top: 40px !important; }

.p-r-0 { padding-right: 0px !important; }
.p-r-1 { padding-right: 1px !important; }
.p-r-2 { padding-right: 2px !important; }
.p-r-3 { padding-right: 3px !important; }
.p-r-4 { padding-right: 4px !important; }
.p-r-5 { padding-right: 5px !important; }
.p-r-10 { padding-right: 10px !important; }
.p-r-15 { padding-right: 15px !important; }
.p-r-20 { padding-right: 20px !important; }
.p-r-25 { padding-right: 25px !important; }
.p-r-30 { padding-right: 30px !important; }
.p-r-35 { padding-right: 35px !important; }
.p-r-40 { padding-right: 40px !important; }

.p-b-0 { padding-bottom: 0px !important; }
.p-b-1 { padding-bottom: 1px !important; }
.p-b-2 { padding-bottom: 2px !important; }
.p-b-3 { padding-bottom: 3px !important; }
.p-b-4 { padding-bottom: 4px !important; }
.p-b-5 { padding-bottom: 5px !important; }
.p-b-10 { padding-bottom: 10px !important; }
.p-b-15 { padding-bottom: 15px !important; }
.p-b-20 { padding-bottom: 20px !important; }
.p-b-25 { padding-bottom: 25px !important; }
.p-b-30 { padding-bottom: 30px !important; }
.p-b-35 { padding-bottom: 35px !important; }
.p-b-40 { padding-bottom: 40px !important; }

.p-l-0 { padding-left: 0px !important; }
.p-l-1 { padding-left: 1px !important; }
.p-l-2 { padding-left: 2px !important; }
.p-l-3 { padding-left: 3px !important; }
.p-l-4 { padding-left: 4px !important; }
.p-l-5 { padding-left: 5px !important; }
.p-l-10 { padding-left: 10px !important; }
.p-l-15 { padding-left: 15px !important; }
.p-l-20 { padding-left: 20px !important; }
.p-l-25 { padding-left: 25px !important; }
.p-l-30 { padding-left: 30px !important; }
.p-l-35 { padding-left: 35px !important; }
.p-l-40 { padding-left: 40px !important; }

.f-s-0 { font-size: 0px !important; }
.f-s-1 { font-size: 1px !important; }
.f-s-2 { font-size: 2px !important; }
.f-s-3 { font-size: 3px !important; }
.f-s-4 { font-size: 4px !important; }
.f-s-5 { font-size: 5px !important; }
.f-s-6 { font-size: 6px !important; }
.f-s-7 { font-size: 7px !important; }
.f-s-8 { font-size: 8px !important; }
.f-s-9 { font-size: 9px !important; }
.f-s-10 { font-size: 10px !important; }
.f-s-11 { font-size: 11px !important; }
.f-s-12 { font-size: 12px !important; }
.f-s-13 { font-size: 13px !important; }
.f-s-14 { font-size: 14px !important; }
.f-s-15 { font-size: 15px !important; }
.f-s-16 { font-size: 16px !important; }
.f-s-17 { font-size: 17px !important; }
.f-s-18 { font-size: 18px !important; }
.f-s-19 { font-size: 19px !important; }
.f-s-20 { font-size: 20px !important; }

.l-h-0 { line-height: 0px !important; }
.l-h-1 { line-height: 1px !important; }
.l-h-2 { line-height: 2px !important; }
.l-h-3 { line-height: 3px !important; }
.l-h-4 { line-height: 4px !important; }
.l-h-5 { line-height: 5px !important; }
.l-h-6 { line-height: 6px !important; }
.l-h-7 { line-height: 7px !important; }
.l-h-8 { line-height: 8px !important; }
.l-h-9 { line-height: 9px !important; }
.l-h-10 { line-height: 10px !important; }
.l-h-11 { line-height: 11px !important; }
.l-h-12 { line-height: 12px !important; }
.l-h-13 { line-height: 13px !important; }
.l-h-14 { line-height: 14px !important; }
.l-h-15 { line-height: 15px !important; }
.l-h-16 { line-height: 16px !important; }
.l-h-17 { line-height: 17px !important; }
.l-h-18 { line-height: 18px !important; }
.l-h-19 { line-height: 19px !important; }
.l-h-20 { line-height: 20px !important; }
.l-h-20 { line-height: 20px !important; }
.l-h-21 { line-height: 21px !important; }
.l-h-22 { line-height: 22px !important; }
.l-h-23 { line-height: 23px !important; }
.l-h-24 { line-height: 24px !important; }
.l-h-25 { line-height: 25px !important; }
.l-h-26 { line-height: 26px !important; }
.l-h-27 { line-height: 27px !important; }
.l-h-28 { line-height: 28px !important; }
.l-h-29 { line-height: 29px !important; }
.l-h-30 { line-height: 30px !important; }

.t-plus-1 { position: relative!important; top: 1px !important; }
.t-plus-2 { position: relative!important; top: 2px !important; }
.t-plus-3 { position: relative!important; top: 3px !important; }
.t-plus-4 { position: relative!important; top: 4px !important; }
.t-plus-5 { position: relative!important; top: 5px !important; }
.t-plus-6 { position: relative!important; top: 6px !important; }
.t-plus-7 { position: relative!important; top: 7px !important; }
.t-plus-8 { position: relative!important; top: 8px !important; }
.t-plus-9 { position: relative!important; top: 9px !important; }
.t-plus-10 { position: relative!important; top: 10px !important; }

.t-minus-1 { position: relative!important; top: -1px !important; }
.t-minus-2 { position: relative!important; top: -2px !important; }
.t-minus-3 { position: relative!important; top: -3px !important; }
.t-minus-4 { position: relative!important; top: -4px !important; }
.t-minus-5 { position: relative!important; top: -5px !important; }
.t-minus-6 { position: relative!important; top: -6px !important; }
.t-minus-7 { position: relative!important; top: -7px !important; }
.t-minus-8 { position: relative!important; top: -8px !important; }
.t-minus-9 { position: relative!important; top: -9px !important; }
.t-minus-10 { position: relative!important; top: -10px !important; }

.l-plus-1 { position: relative!important; left: 1px !important; }
.l-plus-2 { position: relative!important; left: 2px !important; }
.l-plus-3 { position: relative!important; left: 3px !important; }
.l-plus-4 { position: relative!important; left: 4px !important; }
.l-plus-5 { position: relative!important; left: 5px !important; }
.l-plus-6 { position: relative!important; left: 6px !important; }
.l-plus-7 { position: relative!important; left: 7px !important; }
.l-plus-8 { position: relative!important; left: 8px !important; }
.l-plus-9 { position: relative!important; left: 9px !important; }
.l-plus-10 { position: relative!important; left: 10px !important; }

.l-minus-1 { position: relative!important; left: -1px !important; }
.l-minus-2 { position: relative!important; left: -2px !important; }
.l-minus-3 { position: relative!important; left: -3px !important; }
.l-minus-4 { position: relative!important; left: -4px !important; }
.l-minus-5 { position: relative!important; left: -5px !important; }
.l-minus-6 { position: relative!important; left: -6px !important; }
.l-minus-7 { position: relative!important; left: -7px !important; }
.l-minus-8 { position: relative!important; left: -8px !important; }
.l-minus-9 { position: relative!important; left: -9px !important; }
.l-minus-10 { position: relative!important; left: -10px !important; }

.r-plus-1 { position: relative!important; right: 1px !important; }
.r-plus-2 { position: relative!important; right: 2px !important; }
.r-plus-3 { position: relative!important; right: 3px !important; }
.r-plus-4 { position: relative!important; right: 4px !important; }
.r-plus-5 { position: relative!important; right: 5px !important; }
.r-plus-6 { position: relative!important; right: 6px !important; }
.r-plus-7 { position: relative!important; right: 7px !important; }
.r-plus-8 { position: relative!important; right: 8px !important; }
.r-plus-9 { position: relative!important; right: 9px !important; }
.r-plus-10 { position: relative!important; right: 10px !important; }

.r-minus-1 { position: relative!important; right: -1px !important; }
.r-minus-2 { position: relative!important; right: -2px !important; }
.r-minus-3 { position: relative!important; right: -3px !important; }
.r-minus-4 { position: relative!important; right: -4px !important; }
.r-minus-5 { position: relative!important; right: -5px !important; }
.r-minus-6 { position: relative!important; right: -6px !important; }
.r-minus-7 { position: relative!important; right: -7px !important; }
.r-minus-8 { position: relative!important; right: -8px !important; }
.r-minus-9 { position: relative!important; right: -9px !important; }
.r-minus-10 { position: relative!important; right: -10px !important; }

.b-plus-1 { position: relative!important; bottom: 1px !important; }
.b-plus-2 { position: relative!important; bottom: 2px !important; }
.b-plus-3 { position: relative!important; bottom: 3px !important; }
.b-plus-4 { position: relative!important; bottom: 4px !important; }
.b-plus-5 { position: relative!important; bottom: 5px !important; }
.b-plus-6 { position: relative!important; bottom: 6px !important; }
.b-plus-7 { position: relative!important; bottom: 7px !important; }
.b-plus-8 { position: relative!important; bottom: 8px !important; }
.b-plus-9 { position: relative!important; bottom: 9px !important; }
.b-plus-10 { position: relative!important; bottom: 10px !important; }

.b-minus-1 { position: relative!important; bottom: -1px !important; }
.b-minus-2 { position: relative!important; bottom: -2px !important; }
.b-minus-3 { position: relative!important; bottom: -3px !important; }
.b-minus-4 { position: relative!important; bottom: -4px !important; }
.b-minus-5 { position: relative!important; bottom: -5px !important; }
.b-minus-6 { position: relative!important; bottom: -6px !important; }
.b-minus-7 { position: relative!important; bottom: -7px !important; }
.b-minus-8 { position: relative!important; bottom: -8px !important; }
.b-minus-9 { position: relative!important; bottom: -9px !important; }
.b-minus-10 { position: relative!important; bottom: -10px !important; }

.hide { display: none!important; }

.text-center { text-align: center !important; }
.text-left { text-align: left !important; }
.text-right { text-align: right !important; }

.pull-left { float: left !important; }
.pull-right { float: right !important; }
.pull-none { float: none !important; }

.f-w-100 { font-weight: 100 !important; }
.f-w-200 { font-weight: 200 !important; }
.f-w-300 { font-weight: 300 !important; }
.f-w-400 { font-weight: 400 !important; }
.f-w-500 { font-weight: 500 !important; }
.f-w-600, .semi-bold { font-weight: 600 !important; }
.f-w-700 { font-weight: 700 !important; }

.table-valign-middle th, 
.table-valign-middle td { 
    vertical-align: middle !important;
}
.table-th-valign-middle th,
.table-td-valign-middle td { 
    vertical-align: middle !important;
}
.table-no-border-left th:first-child,
.table-no-border-left td:first-child {
	border-left: none !important;
}
.table-no-border-bottom tr:last-child td {
	border-bottom: none !important;
}
.table-no-border-top tr:first-child td {
	border-top: none !important;
}
.table-no-border-right th:last-child,
.table-no-border-right td:last-child {
	border-right: none !important;
}
.table-valign-top th, 
.table-valign-top td { 
    vertical-align: top !important;
}
.table-th-valign-top th,
.table-td-valign-top td { 
    vertical-align: top !important;
}
.table-valign-bottom th, 
.table-valign-bottom td { 
    vertical-align: bottom !important;
}
.table-th-valign-bottom th,
.table-td-valign-bottom td { 
    vertical-align: bottom !important;
}
.valign-top { vertical-align: top !important; }
.valign-bottom { vertical-align: bottom !important; }
.valign-middle { vertical-align: middle !important; }
.vertical-box {
    display: table; 
    table-layout: fixed; 
    border-spacing: 0; 
    height: 100%;
    width: 100%;
}
.vertical-box-column {
    display: table-cell;
    vertical-align: top;
    height: 100%;
}
.vertical-box-row {
    display: table-row;
    height: 100%;
}
.vertical-box-row > .vertical-box-cell {
    position: relative;
    height: 100%;
    width: 100%;
    float: none;
}
.vertical-box-row > .vertical-box-cell > .vertical-box-inner-cell {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: hidden;
}
.vertical-box.with-grid > .vertical-box-column + .vertical-box-column {
	border-left: 1px solid #d9dfe2;
}
.vertical-box.with-grid .vertical-box-row + .vertical-box-row > div,
.vertical-box.with-grid .vertical-box-row + .wrapper,
.vertical-box.with-grid .wrapper + .vertical-box-row > div,
.vertical-box.with-grid .wrapper + .wrapper {
	border-top: 1px solid #d9dfe2;
}
.vertical-box.with-border-top {
	border-top: 1px solid #d9dfe2;
}
.vertical-box.with-border-bottom {
	border-bottom: 1px solid #d9dfe2;
}
.no-rounded-corner { border-radius: 0 !important; }
.rounded-corner { border-radius: 50px !important; }
.no-box-shadow { box-shadow: none !important; }

.no-border { border: 0 !important; }
.b-0 { border: 0 !important; }
.b-t-0 { border-top: 0 !important; }
.b-r-0 { border-right: 0 !important; }
.b-b-0 { border-bottom: 0 !important; }
.b-l-0 { border-left: 0 !important; }
.b-1 { border: 1px solid #f5f5f5 !important; } 
.b-t-1 { border-top: 1px solid #f5f5f5 !important; }
.b-r-1 { border-right: 1px solid #f5f5f5 !important; }
.b-b-1 { border-bottom: 1px solid #f5f5f5 !important; }
.b-l-1 { border-left: 1px solid #f5f5f5 !important; }
.border-top-1 { border-top: 1px solid #f5f5f5 !important; }
.border-right-1 { border-right: 1px solid #f5f5f5 !important; }
.border-bottom-1 { border-bottom: 1px solid #f5f5f5 !important; }
.border-left-1 { border-left: 1px solid #f5f5f5 !important; }

/* Red */

.bg-red,
.bg-red-500,
.bg-danger,
.panel-red .panel-heading,
.panel-danger .panel-heading { 
    background: $red !important; 
}
.bg-red-transparent-1 { background: rgba(244, 67, 54, 0.1) !important; }
.bg-red-transparent-2 { background: rgba(244, 67, 54, 0.2) !important; }
.bg-red-transparent-3 { background: rgba(244, 67, 54, 0.3) !important; }
.bg-red-transparent-4 { background: rgba(244, 67, 54, 0.4) !important; }
.bg-red-transparent-5 { background: rgba(244, 67, 54, 0.5) !important; }
.bg-red-transparent-6 { background: rgba(244, 67, 54, 0.6) !important; }
.bg-red-transparent-7 { background: rgba(244, 67, 54, 0.7) !important; }
.bg-red-transparent-8 { background: rgba(244, 67, 54, 0.8) !important; }
.bg-red-transparent-9 { background: rgba(244, 67, 54, 0.9) !important; }
.bg-red-50 { background: #FFEBEE !important; }
.bg-red-100 { background: #FFCDD2 !important; }
.bg-red-200 { background: #EF9A9A !important; }
.bg-red-300, .bg-red-lighter { background: $red_lighter !important; }
.bg-red-400 { background: #EF5350 !important; }
.bg-red-600 { background: #E53935 !important; }
.bg-red-700, .bg-red-darker { background: $red_darker !important; }
.bg-red-800 { background: #C62828 !important; }
.bg-red-900 { background: #B71C1C !important; }
.text-red,
.text-danger,
.text-red-500 { 
    color: $red !important; 
}
a.text-red:hover,
a.text-red:focus,
a.text-danger:hover,
a.text-danger:focus { 
    color: $red_darker !important; 
}
.text-red-transparent-1 { color: rgba(244, 67, 54, 0.1) !important; }
.text-red-transparent-2 { color: rgba(244, 67, 54, 0.2) !important; }
.text-red-transparent-3 { color: rgba(244, 67, 54, 0.3) !important; }
.text-red-transparent-4 { color: rgba(244, 67, 54, 0.4) !important; }
.text-red-transparent-5 { color: rgba(244, 67, 54, 0.5) !important; }
.text-red-transparent-6 { color: rgba(244, 67, 54, 0.6) !important; }
.text-red-transparent-7 { color: rgba(244, 67, 54, 0.7) !important; }
.text-red-transparent-8 { color: rgba(244, 67, 54, 0.8) !important; }
.text-red-transparent-9 { color: rgba(244, 67, 54, 0.9) !important; }
.text-red-50 { color: #FFEBEE !important; }
.text-red-100 { color: #FFCDD2 !important; }
.text-red-200 { color: #EF9A9A !important; }
.text-red-300, .text-red-lighter { color: $red_lighter !important; }
.text-red-400 { color: #EF5350 !important; }
.text-red-600 { color: #E53935 !important; }
.text-red-700, .text-red-darker { color: $red_darker !important; }
.text-red-800 { color: #C62828 !important; }
.text-red-900 { color: #B71C1C !important; }
.bg-gradient-red {
	background: rgb(229,115,115) !important;
	background: -moz-linear-gradient(top, rgba(229,115,115,1) 0%, rgba(211,47,47,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(229,115,115,1) 0%,rgba(211,47,47,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(229,115,115,1) 0%,rgba(211,47,47,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$red_lighter', endColorstr='$red_darker',GradientType=0 ) !important;
}


/* Pink */

.bg-pink,
.bg-pink-500,
.panel-pink .panel-heading { 
    background: $pink !important; 
}
.bg-pink-transparent-1 { background: rgba(233, 30, 99, 0.1) !important; }
.bg-pink-transparent-2 { background: rgba(233, 30, 99, 0.2) !important; }
.bg-pink-transparent-3 { background: rgba(233, 30, 99, 0.3) !important; }
.bg-pink-transparent-4 { background: rgba(233, 30, 99, 0.4) !important; }
.bg-pink-transparent-5 { background: rgba(233, 30, 99, 0.5) !important; }
.bg-pink-transparent-6 { background: rgba(233, 30, 99, 0.6) !important; }
.bg-pink-transparent-7 { background: rgba(233, 30, 99, 0.7) !important; }
.bg-pink-transparent-8 { background: rgba(233, 30, 99, 0.8) !important; }
.bg-pink-transparent-9 { background: rgba(233, 30, 99, 0.9) !important; }
.bg-pink-50 { background: #FCE4EC !important; }
.bg-pink-100 { background: #F8BBD0 !important; }
.bg-pink-200 { background: #F48FB1 !important; }
.bg-pink-300, .bg-pink-lighter { background: $pink_lighter !important; }
.bg-pink-400 { background: #EC407A !important; }
.bg-pink-600 { background: #D81B60 !important; }
.bg-pink-700, .bg-pink-darker { background: $pink_darker !important; }
.bg-pink-800 { background: #AD1457 !important; }
.bg-pink-900 { background: #880E4F !important; }
.text-pink,
.text-pink-500 { 
    color: $pink !important; 
}
a.text-pink:hover,
a.text-pink:focus { 
    color: $pink_darker !important; 
}
.text-pink-transparent-1 { color: rgba(233, 30, 99, 0.1) !important; }
.text-pink-transparent-2 { color: rgba(233, 30, 99, 0.2) !important; }
.text-pink-transparent-3 { color: rgba(233, 30, 99, 0.3) !important; }
.text-pink-transparent-4 { color: rgba(233, 30, 99, 0.4) !important; }
.text-pink-transparent-5 { color: rgba(233, 30, 99, 0.5) !important; }
.text-pink-transparent-6 { color: rgba(233, 30, 99, 0.6) !important; }
.text-pink-transparent-7 { color: rgba(233, 30, 99, 0.7) !important; }
.text-pink-transparent-8 { color: rgba(233, 30, 99, 0.8) !important; }
.text-pink-transparent-9 { color: rgba(233, 30, 99, 0.9) !important; }
.text-pink-50 { color: #FCE4EC !important; }
.text-pink-100 { color: #F8BBD0 !important; }
.text-pink-200 { color: #F48FB1 !important; }
.text-pink-300, .text-pink-lighter { color: $pink_lighter !important; }
.text-pink-400 { color: #EC407A !important; }
.text-pink-600 { color: #D81B60 !important; }
.text-pink-700, .text-pink-darker { color: $pink_darker !important; }
.text-pink-800 { color: #AD1457 !important; }
.text-pink-900 { color: #880E4F !important; }
.bg-gradient-pink {
	background: rgb(240,98,146) !important;
	background: -moz-linear-gradient(top, rgba(240,98,146,1) 0%, rgba(194,24,91,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(240,98,146,1) 0%,rgba(194,24,91,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(240,98,146,1) 0%,rgba(194,24,91,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$pink_lighter', endColorstr='$pink_darker',GradientType=0 ) !important;
}


/* Purple */

.bg-purple,
.bg-purple-500,
.panel-purple .panel-heading { 
    background: $purple !important; 
}
.bg-purple-transparent-1 { background: rgba(156, 39, 176, 0.1) !important; }
.bg-purple-transparent-2 { background: rgba(156, 39, 176, 0.2) !important; }
.bg-purple-transparent-3 { background: rgba(156, 39, 176, 0.3) !important; }
.bg-purple-transparent-4 { background: rgba(156, 39, 176, 0.4) !important; }
.bg-purple-transparent-5 { background: rgba(156, 39, 176, 0.5) !important; }
.bg-purple-transparent-6 { background: rgba(156, 39, 176, 0.6) !important; }
.bg-purple-transparent-7 { background: rgba(156, 39, 176, 0.7) !important; }
.bg-purple-transparent-8 { background: rgba(156, 39, 176, 0.8) !important; }
.bg-purple-transparent-9 { background: rgba(156, 39, 176, 0.9) !important; }
.bg-purple-50 { background: #F3E5F5 !important; }
.bg-purple-100 { background: #E1BEE7 !important; }
.bg-purple-200 { background: #CE93D8 !important; }
.bg-purple-300, .bg-purple-lighter { background: $purple_lighter !important; }
.bg-purple-400 { background: #AB47BC !important; }
.bg-purple-600 { background: #8E24AA !important; }
.bg-purple-700, .bg-purple-darker { background: $purple_darker !important; }
.bg-purple-800 { background: #6A1B9A !important; }
.bg-purple-900 { background: #4A148C !important; }
.text-purple,
.text-purple-500 { 
    color: $purple !important; 
}
a.text-purple:hover,
a.text-purple:focus { 
    color: $purple_darker !important; 
}
.text-purple-transparent-1 { color: rgba(156, 39, 176, 0.1) !important; }
.text-purple-transparent-2 { color: rgba(156, 39, 176, 0.2) !important; }
.text-purple-transparent-3 { color: rgba(156, 39, 176, 0.3) !important; }
.text-purple-transparent-4 { color: rgba(156, 39, 176, 0.4) !important; }
.text-purple-transparent-5 { color: rgba(156, 39, 176, 0.5) !important; }
.text-purple-transparent-6 { color: rgba(156, 39, 176, 0.6) !important; }
.text-purple-transparent-7 { color: rgba(156, 39, 176, 0.7) !important; }
.text-purple-transparent-8 { color: rgba(156, 39, 176, 0.8) !important; }
.text-purple-transparent-9 { color: rgba(156, 39, 176, 0.9) !important; }
.text-purple-50 { color: #F3E5F5 !important; }
.text-purple-100 { color: #E1BEE7 !important; }
.text-purple-200 { color: #CE93D8 !important; }
.text-purple-300, .text-purple-lighter { color: $purple_lighter !important; }
.text-purple-400 { color: #AB47BC !important; }
.text-purple-600 { color: #8E24AA !important; }
.text-purple-700, .text-purple-darker { color: $purple_darker !important; }
.text-purple-800 { color: #6A1B9A !important; }
.text-purple-900 { color: #4A148C !important; }
.bg-gradient-purple {
	background: rgb(186,104,200) !important;
	background: -moz-linear-gradient(top, rgba(186,104,200,1) 0%, rgba(123,31,162,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(186,104,200,1) 0%,rgba(123,31,162,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(186,104,200,1) 0%,rgba(123,31,162,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$purple_lighter', endColorstr='$purple_darker',GradientType=0 ) !important;
}


/* Deep Purple */

.bg-deep-purple,
.bg-deep-purple-500,
.panel-deep-purple .panel-heading { 
    background: #673AB7 !important; 
}
.bg-deep-purple-transparent-1 { background: rgba(103, 58, 183, 0.1) !important; }
.bg-deep-purple-transparent-2 { background: rgba(103, 58, 183, 0.2) !important; }
.bg-deep-purple-transparent-3 { background: rgba(103, 58, 183, 0.3) !important; }
.bg-deep-purple-transparent-4 { background: rgba(103, 58, 183, 0.4) !important; }
.bg-deep-purple-transparent-5 { background: rgba(103, 58, 183, 0.5) !important; }
.bg-deep-purple-transparent-6 { background: rgba(103, 58, 183, 0.6) !important; }
.bg-deep-purple-transparent-7 { background: rgba(103, 58, 183, 0.7) !important; }
.bg-deep-purple-transparent-8 { background: rgba(103, 58, 183, 0.8) !important; }
.bg-deep-purple-transparent-9 { background: rgba(103, 58, 183, 0.9) !important; }
.bg-deep-purple-50 { background: #EDE7F6 !important; }
.bg-deep-purple-100 { background: #D1C4E9 !important; }
.bg-deep-purple-200 { background: #B39DDB !important; }
.bg-deep-purple-300, .bg-deep-purple-lighter { background: #9575CD !important; }
.bg-deep-purple-400 { background: #7E57C2 !important; }
.bg-deep-purple-600 { background: #5E35B1 !important; }
.bg-deep-purple-700, .bg-deep-purple-darker { background: #512DA8 !important; }
.bg-deep-purple-800 { background: #4527A0 !important; }
.bg-deep-purple-900 { background: #311B92 !important; }
.text-deep-purple,
.text-deep-purple-500 { 
    color: #673AB7 !important; 
}
a.text-deep-purple:hover,
a.text-deep-purple:focus { 
    color: #512DA8 !important; 
}
.text-deep-purple-transparent-1 { color: rgba(103, 58, 183, 0.1) !important; }
.text-deep-purple-transparent-2 { color: rgba(103, 58, 183, 0.2) !important; }
.text-deep-purple-transparent-3 { color: rgba(103, 58, 183, 0.3) !important; }
.text-deep-purple-transparent-4 { color: rgba(103, 58, 183, 0.4) !important; }
.text-deep-purple-transparent-5 { color: rgba(103, 58, 183, 0.5) !important; }
.text-deep-purple-transparent-6 { color: rgba(103, 58, 183, 0.6) !important; }
.text-deep-purple-transparent-7 { color: rgba(103, 58, 183, 0.7) !important; }
.text-deep-purple-transparent-8 { color: rgba(103, 58, 183, 0.8) !important; }
.text-deep-purple-transparent-9 { color: rgba(103, 58, 183, 0.9) !important; }
.text-deep-purple-50 { color: #EDE7F6 !important; }
.text-deep-purple-100 { color: #D1C4E9 !important; }
.text-deep-purple-200 { color: #B39DDB !important; }
.text-deep-purple-300, .text-deep-purple-lighter { color: #9575CD !important; }
.text-deep-purple-400 { color: #7E57C2 !important; }
.text-deep-purple-600 { color: #5E35B1 !important; }
.text-deep-purple-700, .text-deep-purple-darker { color: #512DA8 !important; }
.text-deep-purple-800 { color: #4527A0 !important; }
.text-deep-purple-900 { color: #311B92 !important; }
.bg-gradient-deep-purple {
	background: rgb(149,117,205) !important;
	background: -moz-linear-gradient(top, rgba(149,117,205,1) 0%, rgba(81,45,168,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(149,117,205,1) 0%,rgba(81,45,168,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(149,117,205,1) 0%,rgba(81,45,168,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#9575cd', endColorstr='#512da8',GradientType=0 ) !important;
}


/* Indigo */

.bg-indigo,
.bg-indigo-500,
.panel-indigo .panel-heading { 
    background: $indigo !important; 
}
.bg-indigo-transparent-1 { background: rgba(63, 81, 181, 0.1) !important; }
.bg-indigo-transparent-2 { background: rgba(63, 81, 181, 0.2) !important; }
.bg-indigo-transparent-3 { background: rgba(63, 81, 181, 0.3) !important; }
.bg-indigo-transparent-4 { background: rgba(63, 81, 181, 0.4) !important; }
.bg-indigo-transparent-5 { background: rgba(63, 81, 181, 0.5) !important; }
.bg-indigo-transparent-6 { background: rgba(63, 81, 181, 0.6) !important; }
.bg-indigo-transparent-7 { background: rgba(63, 81, 181, 0.7) !important; }
.bg-indigo-transparent-8 { background: rgba(63, 81, 181, 0.8) !important; }
.bg-indigo-transparent-9 { background: rgba(63, 81, 181, 0.9) !important; }
.bg-indigo-50 { background: #E8EAF6 !important; }
.bg-indigo-100 { background: #C5CAE9 !important; }
.bg-indigo-200 { background: #9FA8DA !important; }
.bg-indigo-300, .bg-indigo-lighter { background: $indigo_lighter !important; }
.bg-indigo-400 { background: #5C6BC0 !important; }
.bg-indigo-600 { background: #3949AB !important; }
.bg-indigo-700, .bg-indigo-darker { background: $indigo_darker !important; }
.bg-indigo-800 { background: #283593 !important; }
.bg-indigo-900 { background: #1A237E !important; }
.text-indigo,
.text-indigo-500 { 
    color: $indigo !important; 
}
a.text-indigo:hover,
a.text-indigo:focus { 
    color: $indigo_darker !important; 
}
.text-indigo-transparent-1 { color: rgba(63, 81, 181, 0.1) !important; }
.text-indigo-transparent-2 { color: rgba(63, 81, 181, 0.2) !important; }
.text-indigo-transparent-3 { color: rgba(63, 81, 181, 0.3) !important; }
.text-indigo-transparent-4 { color: rgba(63, 81, 181, 0.4) !important; }
.text-indigo-transparent-5 { color: rgba(63, 81, 181, 0.5) !important; }
.text-indigo-transparent-6 { color: rgba(63, 81, 181, 0.6) !important; }
.text-indigo-transparent-7 { color: rgba(63, 81, 181, 0.7) !important; }
.text-indigo-transparent-8 { color: rgba(63, 81, 181, 0.8) !important; }
.text-indigo-transparent-9 { color: rgba(63, 81, 181, 0.9) !important; }
.text-indigo-50 { color: #E8EAF6 !important; }
.text-indigo-100 { color: #C5CAE9 !important; }
.text-indigo-200 { color: #9FA8DA !important; }
.text-indigo-300, .text-indigo-lighter { color: $indigo_lighter !important; }
.text-indigo-400 { color: #5C6BC0 !important; }
.text-indigo-600 { color: #3949AB !important; }
.text-indigo-700, .text-indigo-darker { color: $indigo_darker !important; }
.text-indigo-800 { color: #283593 !important; }
.text-indigo-900 { color: #1A237E !important; }
.bg-gradient-indigo {
	background: rgb(121,134,203) !important;
	background: -moz-linear-gradient(top, rgba(121,134,203,1) 0%, rgba(48,63,159,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(121,134,203,1) 0%,rgba(48,63,159,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(121,134,203,1) 0%,rgba(48,63,159,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$indigo_lighter', endColorstr='$indigo_darker',GradientType=0 ) !important;
}


/* Blue */

.bg-blue,
.bg-primary,
.bg-blue-500,
.panel-blue .panel-heading,
.panel-primary .panel-heading { 
    background: $blue !important; 
}
.bg-blue-transparent-1 { background: rgba(33, 150, 243, 0.1) !important; }
.bg-blue-transparent-2 { background: rgba(33, 150, 243, 0.2) !important; }
.bg-blue-transparent-3 { background: rgba(33, 150, 243, 0.3) !important; }
.bg-blue-transparent-4 { background: rgba(33, 150, 243, 0.4) !important; }
.bg-blue-transparent-5 { background: rgba(33, 150, 243, 0.5) !important; }
.bg-blue-transparent-6 { background: rgba(33, 150, 243, 0.6) !important; }
.bg-blue-transparent-7 { background: rgba(33, 150, 243, 0.7) !important; }
.bg-blue-transparent-8 { background: rgba(33, 150, 243, 0.8) !important; }
.bg-blue-transparent-9 { background: rgba(33, 150, 243, 0.9) !important; }
.bg-blue-50 { background: #E3F2FD !important; }
.bg-blue-100 { background: #BBDEFB !important; }
.bg-blue-200 { background: #90CAF9 !important; }
.bg-blue-300, .bg-blue-lighter { background: $blue_lighter !important; }
.bg-blue-400 { background: #42A5F5 !important; }
.bg-blue-600 { background: #1E88E5 !important; }
.bg-blue-700, .bg-blue-darker { background: $blue_darker !important; }
.bg-blue-800 { background: #1565C0 !important; }
.bg-blue-900 { background: #0D47A1 !important; }
.text-blue,
.text-primary,
.text-blue-500 { 
    color: $blue !important; 
}
a.text-blue:hover,
a.text-blue:focus,
a.text-primary:hover,
a.text-primary:focus { 
    color: $blue_darker !important; 
}
.text-blue-transparent-1 { color: rgba(33, 150, 243, 0.1) !important; }
.text-blue-transparent-2 { color: rgba(33, 150, 243, 0.2) !important; }
.text-blue-transparent-3 { color: rgba(33, 150, 243, 0.3) !important; }
.text-blue-transparent-4 { color: rgba(33, 150, 243, 0.4) !important; }
.text-blue-transparent-5 { color: rgba(33, 150, 243, 0.5) !important; }
.text-blue-transparent-6 { color: rgba(33, 150, 243, 0.6) !important; }
.text-blue-transparent-7 { color: rgba(33, 150, 243, 0.7) !important; }
.text-blue-transparent-8 { color: rgba(33, 150, 243, 0.8) !important; }
.text-blue-transparent-9 { color: rgba(33, 150, 243, 0.9) !important; }
.text-blue-50 { color: #E3F2FD !important; }
.text-blue-100 { color: #BBDEFB !important; }
.text-blue-200 { color: #90CAF9 !important; }
.text-blue-300, .text-blue-lighter { color: $blue_lighter !important; }
.text-blue-400 { color: #42A5F5 !important; }
.text-blue-600 { color: #1E88E5 !important; }
.text-blue-700, .text-blue-darker { color: $blue_darker !important; }
.text-blue-800 { color: #1565C0 !important; }
.text-blue-900 { color: #0D47A1 !important; }
.bg-gradient-blue {
	background: rgb(100,181,246) !important;
	background: -moz-linear-gradient(top, rgba(100,181,246,1) 0%, rgba(25,118,210,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(100,181,246,1) 0%,rgba(25,118,210,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(100,181,246,1) 0%,rgba(25,118,210,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$blue_lighter', endColorstr='$blue_darker',GradientType=0 ) !important;
}


/* Light Blue */

.bg-aqua,
.bg-info,
.bg-light-blue,
.bg-light-blue-500,
.panel-light-blue .panel-heading,
.panel-info .panel-heading { 
    background: #03A9F4 !important; 
}
.bg-light-blue-transparent-1, .bg-aqua-transparent-1 { background: rgba(3, 169, 244, 0.1) !important; }
.bg-light-blue-transparent-2, .bg-aqua-transparent-2 { background: rgba(3, 169, 244, 0.2) !important; }
.bg-light-blue-transparent-3, .bg-aqua-transparent-3 { background: rgba(3, 169, 244, 0.3) !important; }
.bg-light-blue-transparent-4, .bg-aqua-transparent-4 { background: rgba(3, 169, 244, 0.4) !important; }
.bg-light-blue-transparent-5, .bg-aqua-transparent-5 { background: rgba(3, 169, 244, 0.5) !important; }
.bg-light-blue-transparent-6, .bg-aqua-transparent-6 { background: rgba(3, 169, 244, 0.6) !important; }
.bg-light-blue-transparent-7, .bg-aqua-transparent-7 { background: rgba(3, 169, 244, 0.7) !important; }
.bg-light-blue-transparent-8, .bg-aqua-transparent-8 { background: rgba(3, 169, 244, 0.8) !important; }
.bg-light-blue-transparent-9, .bg-aqua-transparent-9 { background: rgba(3, 169, 244, 0.9) !important; }
.bg-light-blue-50 { background: #E1F5FE !important; }
.bg-light-blue-100 { background: #B3E5FC !important; }
.bg-light-blue-200 { background: #81D4FA !important; }
.bg-light-blue-300, .bg-light-blue-lighter, .bg-aqua-lighter { background: #4FC3F7 !important; }
.bg-light-blue-400 { background: #29B6F6 !important; }
.bg-light-blue-600 { background: #039BE5 !important; }
.bg-light-blue-700, .bg-light-blue-darker, .bg-aqua-darker { background: #0288D1 !important; }
.bg-light-blue-800 { background: #0277BD !important; }
.bg-light-blue-900 { background: #01579B !important; }
.text-aqua,
.text-info,
.text-light-blue,
.text-light-blue-500 { 
    color: #03A9F4 !important; 
}
a.text-light-blue:hover,
a.text-light-blue:focus { 
    color: #0288D1 !important; 
}
.text-light-blue-transparent-1, .text-aqua-transparent-1 { color: rgba(3, 169, 244, 0.1) !important; }
.text-light-blue-transparent-2, .text-aqua-transparent-2 { color: rgba(3, 169, 244, 0.2) !important; }
.text-light-blue-transparent-3, .text-aqua-transparent-3 { color: rgba(3, 169, 244, 0.3) !important; }
.text-light-blue-transparent-4, .text-aqua-transparent-4 { color: rgba(3, 169, 244, 0.4) !important; }
.text-light-blue-transparent-5, .text-aqua-transparent-5 { color: rgba(3, 169, 244, 0.5) !important; }
.text-light-blue-transparent-6, .text-aqua-transparent-6 { color: rgba(3, 169, 244, 0.6) !important; }
.text-light-blue-transparent-7, .text-aqua-transparent-7 { color: rgba(3, 169, 244, 0.7) !important; }
.text-light-blue-transparent-8, .text-aqua-transparent-8 { color: rgba(3, 169, 244, 0.8) !important; }
.text-light-blue-transparent-9, .text-aqua-transparent-9 { color: rgba(3, 169, 244, 0.9) !important; }
.text-light-blue-50 { color: #E1F5FE !important; }
.text-light-blue-100 { color: #B3E5FC !important; }
.text-light-blue-200 { color: #81D4FA !important; }
.text-light-blue-300, .text-light-blue-lighter, .text-aqua-lighter { color: #4FC3F7 !important; }
.text-light-blue-400 { color: #29B6F6 !important; }
.text-light-blue-600 { color: #039BE5 !important; }
.text-light-blue-700, .text-light-blue-darker, .text-aqua-darker { color: #0288D1 !important; }
.text-light-blue-800 { color: #0277BD !important; }
.text-light-blue-900 { color: #01579B !important; }
.bg-gradient-light-blue,
.bg-gradient-aqua {
	background: rgb(79,195,247) !important;
	background: -moz-linear-gradient(top, rgba(79,195,247,1) 0%, rgba(2,136,209,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(79,195,247,1) 0%,rgba(2,136,209,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(79,195,247,1) 0%,rgba(2,136,209,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4fc3f7', endColorstr='#0288d1',GradientType=0 ) !important;
}


/* Cyan */

.bg-cyan,
.bg-cyan-500,
.panel-cyan .panel-heading { 
    background: $aqua !important; 
}
.bg-cyan-transparent-1 { background: rgba(0, 188, 212, 0.1) !important; }
.bg-cyan-transparent-2 { background: rgba(0, 188, 212, 0.2) !important; }
.bg-cyan-transparent-3 { background: rgba(0, 188, 212, 0.3) !important; }
.bg-cyan-transparent-4 { background: rgba(0, 188, 212, 0.4) !important; }
.bg-cyan-transparent-5 { background: rgba(0, 188, 212, 0.5) !important; }
.bg-cyan-transparent-6 { background: rgba(0, 188, 212, 0.6) !important; }
.bg-cyan-transparent-7 { background: rgba(0, 188, 212, 0.7) !important; }
.bg-cyan-transparent-8 { background: rgba(0, 188, 212, 0.8) !important; }
.bg-cyan-transparent-9 { background: rgba(0, 188, 212, 0.9) !important; }
.bg-cyan-50 { background: #E0F7FA !important; }
.bg-cyan-100 { background: #B2EBF2 !important; }
.bg-cyan-200 { background: #80DEEA !important; }
.bg-cyan-300, .bg-cyan-lighter { background: $aqua_lighter !important; }
.bg-cyan-400 { background: #26C6DA !important; }
.bg-cyan-600 { background: #00ACC1 !important; }
.bg-cyan-700, .bg-cyan-darker { background: $aqua_darker !important; }
.bg-cyan-800 { background: #00838F !important; }
.bg-cyan-900 { background: #006064 !important; }
.text-cyan,
.text-cyan-500 { 
    color: $aqua !important; 
}
a.text-cyan:hover,
a.text-cyan:focus { 
    color: $aqua_darker !important; 
}
.text-cyan-transparent-1 { color: rgba(0, 188, 212, 0.1) !important; }
.text-cyan-transparent-2 { color: rgba(0, 188, 212, 0.2) !important; }
.text-cyan-transparent-3 { color: rgba(0, 188, 212, 0.3) !important; }
.text-cyan-transparent-4 { color: rgba(0, 188, 212, 0.4) !important; }
.text-cyan-transparent-5 { color: rgba(0, 188, 212, 0.5) !important; }
.text-cyan-transparent-6 { color: rgba(0, 188, 212, 0.6) !important; }
.text-cyan-transparent-7 { color: rgba(0, 188, 212, 0.7) !important; }
.text-cyan-transparent-8 { color: rgba(0, 188, 212, 0.8) !important; }
.text-cyan-transparent-9 { color: rgba(0, 188, 212, 0.9) !important; }
.text-cyan-50 { color: #E0F7FA !important; }
.text-cyan-100 { color: #B2EBF2 !important; }
.text-cyan-200 { color: #80DEEA !important; }
.text-cyan-300, .text-cyan-lighter { color: $aqua_lighter !important; }
.text-cyan-400 { color: #26C6DA !important; }
.text-cyan-600 { color: #00ACC1 !important; }
.text-cyan-700, .text-cyan-darker { color: $aqua_darker !important; }
.text-cyan-800 { color: #00838F !important; }
.text-cyan-900 { color: #006064 !important; }
.bg-gradient-cyan {
	background: rgb(77,208,225) !important;
	background: -moz-linear-gradient(top, rgba(77,208,225,1) 0%, rgba(0,151,167,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(77,208,225,1) 0%,rgba(0,151,167,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(77,208,225,1) 0%,rgba(0,151,167,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$aqua_lighter', endColorstr='$aqua_darker',GradientType=0 ) !important;
}


/* Teal */

.bg-teal,
.bg-success,
.bg-teal-500,
.panel-teal .panel-heading,
.panel-success .panel-heading { 
    background: $teal !important; 
}
.bg-teal-transparent-1 { background: rgba(0, 150, 136, 0.1) !important; }
.bg-teal-transparent-2 { background: rgba(0, 150, 136, 0.2) !important; }
.bg-teal-transparent-3 { background: rgba(0, 150, 136, 0.3) !important; }
.bg-teal-transparent-4 { background: rgba(0, 150, 136, 0.4) !important; }
.bg-teal-transparent-5 { background: rgba(0, 150, 136, 0.5) !important; }
.bg-teal-transparent-6 { background: rgba(0, 150, 136, 0.6) !important; }
.bg-teal-transparent-7 { background: rgba(0, 150, 136, 0.7) !important; }
.bg-teal-transparent-8 { background: rgba(0, 150, 136, 0.8) !important; }
.bg-teal-transparent-9 { background: rgba(0, 150, 136, 0.9) !important; }
.bg-teal-50 { background: #E0F2F1 !important; }
.bg-teal-100 { background: #B2DFDB !important; }
.bg-teal-200 { background: #80CBC4 !important; }
.bg-teal-300, .bg-teal-lighter { background: $teal_lighter !important; }
.bg-teal-400 { background: #26A69A !important; }
.bg-teal-600 { background: #00897B !important; }
.bg-teal-700, .bg-teal-darker { background: $teal_darker !important; }
.bg-teal-800 { background: #00695C !important; }
.bg-teal-900 { background: #004D40 !important; }
.text-teal,
.text-success,
.text-teal-500 { 
    color: $teal !important; 
}
a.text-teal:hover,
a.text-teal:focus,
a.text-success:hover,
a.text-success:focus { 
    color: $teal_darker !important; 
}
.text-teal-transparent-1 { color: rgba(0, 150, 136, 0.1) !important; }
.text-teal-transparent-2 { color: rgba(0, 150, 136, 0.2) !important; }
.text-teal-transparent-3 { color: rgba(0, 150, 136, 0.3) !important; }
.text-teal-transparent-4 { color: rgba(0, 150, 136, 0.4) !important; }
.text-teal-transparent-5 { color: rgba(0, 150, 136, 0.5) !important; }
.text-teal-transparent-6 { color: rgba(0, 150, 136, 0.6) !important; }
.text-teal-transparent-7 { color: rgba(0, 150, 136, 0.7) !important; }
.text-teal-transparent-8 { color: rgba(0, 150, 136, 0.8) !important; }
.text-teal-transparent-9 { color: rgba(0, 150, 136, 0.9) !important; }
.text-teal-50 { color: #E0F2F1 !important; }
.text-teal-100 { color: #B2DFDB !important; }
.text-teal-200 { color: #80CBC4 !important; }
.text-teal-300, .text-teal-lighter { color: $teal_lighter !important; }
.text-teal-400 { color: #26A69A !important; }
.text-teal-600 { color: #00897B !important; }
.text-teal-700, .text-teal-darker { color: $teal_darker !important; }
.text-teal-800 { color: #00695C !important; }
.text-teal-900 { color: #004D40 !important; }
.bg-gradient-teal {
	background: rgb(77,182,172) !important;
	background: -moz-linear-gradient(top, rgba(77,182,172,1) 0%, rgba(0,121,107,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(77,182,172,1) 0%,rgba(0,121,107,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(77,182,172,1) 0%,rgba(0,121,107,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$teal_lighter', endColorstr='$teal_darker',GradientType=0 ) !important;
}


/* Green */

.bg-green,
.bg-green-500,
.panel-green .panel-heading { 
    background: #4CAF50 !important; 
}
.bg-green-transparent-1 { background: rgba(76, 175, 80, 0.1) !important; }
.bg-green-transparent-2 { background: rgba(76, 175, 80, 0.2) !important; }
.bg-green-transparent-3 { background: rgba(76, 175, 80, 0.3) !important; }
.bg-green-transparent-4 { background: rgba(76, 175, 80, 0.4) !important; }
.bg-green-transparent-5 { background: rgba(76, 175, 80, 0.5) !important; }
.bg-green-transparent-6 { background: rgba(76, 175, 80, 0.6) !important; }
.bg-green-transparent-7 { background: rgba(76, 175, 80, 0.7) !important; }
.bg-green-transparent-8 { background: rgba(76, 175, 80, 0.8) !important; }
.bg-green-transparent-9 { background: rgba(76, 175, 80, 0.9) !important; }
.bg-green-50 { background: #E8F5E9 !important; }
.bg-green-100 { background: #C8E6C9 !important; }
.bg-green-200 { background: #A5D6A7 !important; }
.bg-green-300, .bg-green-lighter { background: #81C784 !important; }
.bg-green-400 { background: #66BB6A !important; }
.bg-green-600 { background: #43A047 !important; }
.bg-green-700, .bg-green-darker { background: #388E3C !important; }
.bg-green-800 { background: #2E7D32 !important; }
.bg-green-900 { background: #1B5E20 !important; }
.text-green,
.text-green-500 { 
    color: #4CAF50 !important; 
}
a.text-green:hover,
a.text-green:focus { 
    color: #388E3C !important; 
}
.text-green-transparent-1 { color: rgba(76, 175, 80, 0.1) !important; }
.text-green-transparent-2 { color: rgba(76, 175, 80, 0.2) !important; }
.text-green-transparent-3 { color: rgba(76, 175, 80, 0.3) !important; }
.text-green-transparent-4 { color: rgba(76, 175, 80, 0.4) !important; }
.text-green-transparent-5 { color: rgba(76, 175, 80, 0.5) !important; }
.text-green-transparent-6 { color: rgba(76, 175, 80, 0.6) !important; }
.text-green-transparent-7 { color: rgba(76, 175, 80, 0.7) !important; }
.text-green-transparent-8 { color: rgba(76, 175, 80, 0.8) !important; }
.text-green-transparent-9 { color: rgba(76, 175, 80, 0.9) !important; }
.text-green-50 { color: #E8F5E9 !important; }
.text-green-100 { color: #C8E6C9 !important; }
.text-green-200 { color: #A5D6A7 !important; }
.text-green-300, .text-green-lighter { color: #81C784 !important; }
.text-green-400 { color: #66BB6A !important; }
.text-green-600 { color: #43A047 !important; }
.text-green-700, .text-green-darker { color: #388E3C !important; }
.text-green-800 { color: #2E7D32 !important; }
.text-green-900 { color: #1B5E20 !important; }
.bg-gradient-green {
	background: rgb(129,199,132) !important;
	background: -moz-linear-gradient(top, rgba(129,199,132,1) 0%, rgba(56,142,60,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(129,199,132,1) 0%,rgba(56,142,60,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(129,199,132,1) 0%,rgba(56,142,60,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#81c784', endColorstr='#388e3c',GradientType=0 ) !important;	
}


/* Light Green */

.bg-light-green,
.bg-light-green-500,
.panel-light-green .panel-heading { 
    background: $green !important; 
}
.bg-light-green-transparent-1 { background: rgba(139, 195, 74, 0.1) !important; }
.bg-light-green-transparent-2 { background: rgba(139, 195, 74, 0.2) !important; }
.bg-light-green-transparent-3 { background: rgba(139, 195, 74, 0.3) !important; }
.bg-light-green-transparent-4 { background: rgba(139, 195, 74, 0.4) !important; }
.bg-light-green-transparent-5 { background: rgba(139, 195, 74, 0.5) !important; }
.bg-light-green-transparent-6 { background: rgba(139, 195, 74, 0.6) !important; }
.bg-light-green-transparent-7 { background: rgba(139, 195, 74, 0.7) !important; }
.bg-light-green-transparent-8 { background: rgba(139, 195, 74, 0.8) !important; }
.bg-light-green-transparent-9 { background: rgba(139, 195, 74, 0.9) !important; }
.bg-light-green-50 { background: #F1F8E9 !important; }
.bg-light-green-100 { background: #DCEDC8 !important; }
.bg-light-green-200 { background: #C5E1A5 !important; }
.bg-light-green-300, .bg-light-green-lighter { background: $green_lighter !important; }
.bg-light-green-400 { background: #9CCC65 !important; }
.bg-light-green-600 { background: #7CB342 !important; }
.bg-light-green-700, .bg-light-green-darker { background: $green_darker !important; }
.bg-light-green-800 { background: #558B2F !important; }
.bg-light-green-900 { background: #FF6F00 !important; }
.text-light-green,
.text-light-green-500 { 
    color: $green !important; 
}
a.text-light-green:hover,
a.text-light-green:focus { 
    color: $green_darker !important; 
}
.text-light-green-transparent-1 { color: rgba(139, 195, 74, 0.1) !important; }
.text-light-green-transparent-2 { color: rgba(139, 195, 74, 0.2) !important; }
.text-light-green-transparent-3 { color: rgba(139, 195, 74, 0.3) !important; }
.text-light-green-transparent-4 { color: rgba(139, 195, 74, 0.4) !important; }
.text-light-green-transparent-5 { color: rgba(139, 195, 74, 0.5) !important; }
.text-light-green-transparent-6 { color: rgba(139, 195, 74, 0.6) !important; }
.text-light-green-transparent-7 { color: rgba(139, 195, 74, 0.7) !important; }
.text-light-green-transparent-8 { color: rgba(139, 195, 74, 0.8) !important; }
.text-light-green-transparent-9 { color: rgba(139, 195, 74, 0.9) !important; }
.text-light-green-50 { color: #F1F8E9 !important; }
.text-light-green-100 { color: #DCEDC8 !important; }
.text-light-green-200 { color: #C5E1A5 !important; }
.text-light-green-300, .text-light-green-lighter { color: $green_lighter !important; }
.text-light-green-400 { color: #9CCC65 !important; }
.text-light-green-600 { color: #7CB342 !important; }
.text-light-green-700, .text-light-green-darker { color: $green_darker !important; }
.text-light-green-800 { color: #558B2F !important; }
.text-light-green-900 { color: #33691E !important; }
.bg-gradient-light-green {
	background: rgb(174,213,129) !important;
	background: -moz-linear-gradient(top, rgba(174,213,129,1) 0%, rgba(104,159,56,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(174,213,129,1) 0%,rgba(104,159,56,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(174,213,129,1) 0%,rgba(104,159,56,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$green_lighter', endColorstr='$green_darker',GradientType=0 ) !important;
}


/* Lime */

.bg-lime,
.bg-lime-500,
.panel-lime .panel-heading { 
    background: $lime !important; 
}
.bg-lime-transparent-1 { background: rgba(205, 220, 57, 0.1) !important; }
.bg-lime-transparent-2 { background: rgba(205, 220, 57, 0.2) !important; }
.bg-lime-transparent-3 { background: rgba(205, 220, 57, 0.3) !important; }
.bg-lime-transparent-4 { background: rgba(205, 220, 57, 0.4) !important; }
.bg-lime-transparent-5 { background: rgba(205, 220, 57, 0.5) !important; }
.bg-lime-transparent-6 { background: rgba(205, 220, 57, 0.6) !important; }
.bg-lime-transparent-7 { background: rgba(205, 220, 57, 0.7) !important; }
.bg-lime-transparent-8 { background: rgba(205, 220, 57, 0.8) !important; }
.bg-lime-transparent-9 { background: rgba(205, 220, 57, 0.9) !important; }
.bg-lime-50 { background: #F9FBE7 !important; }
.bg-lime-100 { background: #F0F4C3 !important; }
.bg-lime-200 { background: #E6EE9C !important; }
.bg-lime-300, .bg-lime-lighter { background: $lime_lighter !important; }
.bg-lime-400 { background: #D4E157 !important; }
.bg-lime-600 { background: #C0CA33 !important; }
.bg-lime-700, .bg-lime-darker { background: $lime_darker !important; }
.bg-lime-800 { background: #9E9D24 !important; }
.bg-lime-900 { background: #827717 !important; }
.text-lime,
.text-lime-500 { 
    color: $lime !important; 
}
a.text-light-lime:hover,
a.text-light-lime:focus { 
    color: $lime_darker !important; 
}
.text-lime-transparent-1 { color: rgba(205, 220, 57, 0.1) !important; }
.text-lime-transparent-2 { color: rgba(205, 220, 57, 0.2) !important; }
.text-lime-transparent-3 { color: rgba(205, 220, 57, 0.3) !important; }
.text-lime-transparent-4 { color: rgba(205, 220, 57, 0.4) !important; }
.text-lime-transparent-5 { color: rgba(205, 220, 57, 0.5) !important; }
.text-lime-transparent-6 { color: rgba(205, 220, 57, 0.6) !important; }
.text-lime-transparent-7 { color: rgba(205, 220, 57, 0.7) !important; }
.text-lime-transparent-8 { color: rgba(205, 220, 57, 0.8) !important; }
.text-lime-transparent-9 { color: rgba(205, 220, 57, 0.9) !important; }
.text-lime-50 { color: #F9FBE7 !important; }
.text-lime-100 { color: #F0F4C3 !important; }
.text-lime-200 { color: #E6EE9C !important; }
.text-lime-300, .text-lime-lighter { color: $lime_lighter !important; }
.text-lime-400 { color: #D4E157 !important; }
.text-lime-600 { color: #C0CA33 !important; }
.text-lime-700, .text-lime-darker { color: $lime_darker !important; }
.text-lime-800 { color: #9E9D24 !important; }
.text-lime-900 { color: #827717 !important; }
.bg-gradient-lime {
	background: rgb(220,231,117) !important;
	background: -moz-linear-gradient(top, rgba(220,231,117,1) 0%, rgba(175,180,43,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(220,231,117,1) 0%,rgba(175,180,43,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(220,231,117,1) 0%,rgba(175,180,43,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$lime_lighter', endColorstr='$lime_darker',GradientType=0 ) !important;
}


/* Yellow */

.bg-yellow,
.bg-yellow-500,
.panel-yellow .panel-heading { 
    background: $yellow !important; 
}
.bg-yellow-transparent-1 { background: rgba(255, 235, 59, 0.1) !important; }
.bg-yellow-transparent-2 { background: rgba(255, 235, 59, 0.2) !important; }
.bg-yellow-transparent-3 { background: rgba(255, 235, 59, 0.3) !important; }
.bg-yellow-transparent-4 { background: rgba(255, 235, 59, 0.4) !important; }
.bg-yellow-transparent-5 { background: rgba(255, 235, 59, 0.5) !important; }
.bg-yellow-transparent-6 { background: rgba(255, 235, 59, 0.6) !important; }
.bg-yellow-transparent-7 { background: rgba(255, 235, 59, 0.7) !important; }
.bg-yellow-transparent-8 { background: rgba(255, 235, 59, 0.8) !important; }
.bg-yellow-transparent-9 { background: rgba(255, 235, 59, 0.9) !important; }
.bg-yellow-50 { background: #FFFDE7 !important; }
.bg-yellow-100 { background: #FFF9C4 !important; }
.bg-yellow-200 { background: #FFF59D !important; }
.bg-yellow-300, .bg-yellow-lighter { background: $yellow_lighter !important; }
.bg-yellow-400 { background: #FFEE58 !important; }
.bg-yellow-600 { background: #FDD835 !important; }
.bg-yellow-700, .bg-yellow-darker { background: $yellow_darker !important; }
.bg-yellow-800 { background: #F9A825 !important; }
.bg-yellow-900 { background: #F57F17 !important; }
.text-yellow,
.text-yellow-500 { 
    color: $yellow !important; 
}
a.text-yellow:hover,
a.text-yellow:focus { 
    color: $yellow_darker !important; 
}
.text-yellow-transparent-1 { color: rgba(255, 235, 59, 0.1) !important; }
.text-yellow-transparent-2 { color: rgba(255, 235, 59, 0.2) !important; }
.text-yellow-transparent-3 { color: rgba(255, 235, 59, 0.3) !important; }
.text-yellow-transparent-4 { color: rgba(255, 235, 59, 0.4) !important; }
.text-yellow-transparent-5 { color: rgba(255, 235, 59, 0.5) !important; }
.text-yellow-transparent-6 { color: rgba(255, 235, 59, 0.6) !important; }
.text-yellow-transparent-7 { color: rgba(255, 235, 59, 0.7) !important; }
.text-yellow-transparent-8 { color: rgba(255, 235, 59, 0.8) !important; }
.text-yellow-transparent-9 { color: rgba(255, 235, 59, 0.9) !important; }
.text-yellow-50 { color: #FFFDE7 !important; }
.text-yellow-100 { color: #FFF9C4 !important; }
.text-yellow-200 { color: #FFF59D !important; }
.text-yellow-300, .text-yellow-lighter { color: $yellow_lighter !important; }
.text-yellow-400 { color: #FFEE58 !important; }
.text-yellow-600 { color: #FDD835 !important; }
.text-yellow-700, .text-yellow-darker { color: $yellow_darker !important; }
.text-yellow-800 { color: #F9A825 !important; }
.text-yellow-900 { color: #F57F17 !important; }
.bg-gradient-yellow {
	background: rgb(255,241,118) !important;
	background: -moz-linear-gradient(top, rgba(255,241,118,1) 0%, rgba(251,192,45,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(255,241,118,1) 0%,rgba(251,192,45,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(255,241,118,1) 0%,rgba(251,192,45,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$yellow_lighter', endColorstr='$yellow_darker',GradientType=0 ) !important;
}


/* Ember */

.bg-amber,
.bg-amber-500,
.panel-amber .panel-heading { 
    background: #FFC107 !important; 
}
.bg-amber-transparent-1 { background: rgba(255, 193, 7, 0.1) !important; }
.bg-amber-transparent-2 { background: rgba(255, 193, 7, 0.2) !important; }
.bg-amber-transparent-3 { background: rgba(255, 193, 7, 0.3) !important; }
.bg-amber-transparent-4 { background: rgba(255, 193, 7, 0.4) !important; }
.bg-amber-transparent-5 { background: rgba(255, 193, 7, 0.5) !important; }
.bg-amber-transparent-6 { background: rgba(255, 193, 7, 0.6) !important; }
.bg-amber-transparent-7 { background: rgba(255, 193, 7, 0.7) !important; }
.bg-amber-transparent-8 { background: rgba(255, 193, 7, 0.8) !important; }
.bg-amber-transparent-9 { background: rgba(255, 193, 7, 0.9) !important; }
.bg-amber-50 { background: #FFF8E1 !important; }
.bg-amber-100 { background: #FFECB3 !important; }
.bg-amber-200 { background: #FFE082 !important; }
.bg-amber-300, .bg-amber-lighter { background: #FFD54F !important; }
.bg-amber-400 { background: #FFCA28 !important; }
.bg-amber-600 { background: #FFB300 !important; }
.bg-amber-700, .bg-amber-darker { background: #FFA000 !important; }
.bg-amber-800 { background: #FF8F00 !important; }
.bg-amber-900 { background: #FF6F00 !important; }
.text-amber,
.text-amber-500 { 
    color: #FFC107 !important; 
}
a.text-amber:hover,
a.text-amber:focus { 
    color: #FFA000 !important; 
}
.text-amber-transparent-1 { color: rgba(255, 193, 7, 0.1) !important; }
.text-amber-transparent-2 { color: rgba(255, 193, 7, 0.2) !important; }
.text-amber-transparent-3 { color: rgba(255, 193, 7, 0.3) !important; }
.text-amber-transparent-4 { color: rgba(255, 193, 7, 0.4) !important; }
.text-amber-transparent-5 { color: rgba(255, 193, 7, 0.5) !important; }
.text-amber-transparent-6 { color: rgba(255, 193, 7, 0.6) !important; }
.text-amber-transparent-7 { color: rgba(255, 193, 7, 0.7) !important; }
.text-amber-transparent-8 { color: rgba(255, 193, 7, 0.8) !important; }
.text-amber-transparent-9 { color: rgba(255, 193, 7, 0.9) !important; }
.text-amber-50 { color: #FFF8E1 !important; }
.text-amber-100 { color: #FFECB3 !important; }
.text-amber-200 { color: #FFE082 !important; }
.text-amber-300, .text-amber-lighter { color: #FFD54F !important; }
.text-amber-400 { color: #FFCA28 !important; }
.text-amber-600 { color: #FFB300 !important; }
.text-amber-700, .text-amber-darker { color: #FFA000 !important; }
.text-amber-800 { color: #FF8F00 !important; }
.text-amber-900 { color: #FF6F00 !important; }
.bg-gradient-amber {
	background: rgb(255,213,79) !important;
	background: -moz-linear-gradient(top, rgba(255,213,79,1) 0%, rgba(255,160,0,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(255,213,79,1) 0%,rgba(255,160,0,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(255,213,79,1) 0%,rgba(255,160,0,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd54f', endColorstr='#ffa000',GradientType=0 ) !important;
}


/* Orange */

.bg-orange,
.bg-warning,
.bg-orange-500,
.panel-orange .panel-heading,
.panel-warning .panel-heading { 
    background: $orange !important; 
}
.bg-orange-transparent-1 { background: rgba(255, 152, 0, 0.1) !important; }
.bg-orange-transparent-2 { background: rgba(255, 152, 0, 0.2) !important; }
.bg-orange-transparent-3 { background: rgba(255, 152, 0, 0.3) !important; }
.bg-orange-transparent-4 { background: rgba(255, 152, 0, 0.4) !important; }
.bg-orange-transparent-5 { background: rgba(255, 152, 0, 0.5) !important; }
.bg-orange-transparent-6 { background: rgba(255, 152, 0, 0.6) !important; }
.bg-orange-transparent-7 { background: rgba(255, 152, 0, 0.7) !important; }
.bg-orange-transparent-8 { background: rgba(255, 152, 0, 0.8) !important; }
.bg-orange-transparent-9 { background: rgba(255, 152, 0, 0.9) !important; }
.bg-orange-50 { background: #FFF3E0 !important; }
.bg-orange-100 { background: #FFE0B2 !important; }
.bg-orange-200 { background: #FFCC80 !important; }
.bg-orange-300, .bg-orange-lighter { background: $orange_lighter !important; }
.bg-orange-400 { background: #FFA726 !important; }
.bg-orange-600 { background: #FB8C00 !important; }
.bg-orange-700, .bg-orange-darker { background: $orange_darker !important; }
.bg-orange-800 { background: #EF6C00 !important; }
.bg-orange-900 { background: #E65100 !important; }
.text-orange,
.text-warning,
.text-orange-500 { 
    color: $orange !important; 
}
a.text-orange:hover,
a.text-orange:focus,
a.text-warning:hover,
a.text-warning:focus { 
    color: $orange_darker !important; 
}
.text-orange-transparent-1 { color: rgba(255, 152, 0, 0.1) !important; }
.text-orange-transparent-2 { color: rgba(255, 152, 0, 0.2) !important; }
.text-orange-transparent-3 { color: rgba(255, 152, 0, 0.3) !important; }
.text-orange-transparent-4 { color: rgba(255, 152, 0, 0.4) !important; }
.text-orange-transparent-5 { color: rgba(255, 152, 0, 0.5) !important; }
.text-orange-transparent-6 { color: rgba(255, 152, 0, 0.6) !important; }
.text-orange-transparent-7 { color: rgba(255, 152, 0, 0.7) !important; }
.text-orange-transparent-8 { color: rgba(255, 152, 0, 0.8) !important; }
.text-orange-transparent-9 { color: rgba(255, 152, 0, 0.9) !important; }
.text-orange-50 { color: #FFF3E0 !important; }
.text-orange-100 { color: #FFE0B2 !important; }
.text-orange-200 { color: #FFCC80 !important; }
.text-orange-300, .text-orange-lighter { color: $orange_lighter !important; }
.text-orange-400 { color: #FFA726 !important; }
.text-orange-600 { color: #FB8C00 !important; }
.text-orange-700, .text-orange-darker { color: $orange_darker !important; }
.text-orange-800 { color: #EF6C00 !important; }
.text-orange-900 { color: #E65100 !important; }
.bg-gradient-orange {
	background: rgb(255,183,77) !important;
	background: -moz-linear-gradient(top, rgba(255,183,77,1) 0%, rgba(245,124,0,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(255,183,77,1) 0%,rgba(245,124,0,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(255,183,77,1) 0%,rgba(245,124,0,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$orange_lighter', endColorstr='$orange_darker',GradientType=0 ) !important;
}


/* Deep Orange */

.bg-deep-orange,
.bg-deep-orange-500,
.panel-deep-orange .panel-heading { 
    background: #FF5722 !important; 
}
.bg-deep-orange-transparent-1 { background: rgba(255, 87, 34, 0.1) !important; }
.bg-deep-orange-transparent-2 { background: rgba(255, 87, 34, 0.2) !important; }
.bg-deep-orange-transparent-3 { background: rgba(255, 87, 34, 0.3) !important; }
.bg-deep-orange-transparent-4 { background: rgba(255, 87, 34, 0.4) !important; }
.bg-deep-orange-transparent-5 { background: rgba(255, 87, 34, 0.5) !important; }
.bg-deep-orange-transparent-6 { background: rgba(255, 87, 34, 0.6) !important; }
.bg-deep-orange-transparent-7 { background: rgba(255, 87, 34, 0.7) !important; }
.bg-deep-orange-transparent-8 { background: rgba(255, 87, 34, 0.8) !important; }
.bg-deep-orange-transparent-9 { background: rgba(255, 87, 34, 0.9) !important; }
.bg-deep-orange-50 { background: #FBE9E7 !important; }
.bg-deep-orange-100 { background: #FFCCBC !important; }
.bg-deep-orange-200 { background: #FFAB91 !important; }
.bg-deep-orange-300, .bg-deep-orange-lighter { background: #FF8A65 !important; }
.bg-deep-orange-400 { background: #FF7043 !important; }
.bg-deep-orange-600 { background: #F4511E !important; }
.bg-deep-orange-700, .bg-deep-orange-darker { background: #E64A19 !important; }
.bg-deep-orange-800 { background: #D84315 !important; }
.bg-deep-orange-900 { background: #BF360C !important; }
.text-deep-orange,
.text-deep-orange-500 { 
    color: #FF5722 !important; 
}
a.text-deep-orange:hover,
a.text-deep-orange:focus { 
    color: #E64A19 !important; 
}
.text-deep-orange-transparent-1 { color: rgba(255, 87, 34, 0.1) !important; }
.text-deep-orange-transparent-2 { color: rgba(255, 87, 34, 0.2) !important; }
.text-deep-orange-transparent-3 { color: rgba(255, 87, 34, 0.3) !important; }
.text-deep-orange-transparent-4 { color: rgba(255, 87, 34, 0.4) !important; }
.text-deep-orange-transparent-5 { color: rgba(255, 87, 34, 0.5) !important; }
.text-deep-orange-transparent-6 { color: rgba(255, 87, 34, 0.6) !important; }
.text-deep-orange-transparent-7 { color: rgba(255, 87, 34, 0.7) !important; }
.text-deep-orange-transparent-8 { color: rgba(255, 87, 34, 0.8) !important; }
.text-deep-orange-transparent-9 { color: rgba(255, 87, 34, 0.9) !important; }
.text-deep-orange-50 { color: #FBE9E7 !important; }
.text-deep-orange-100 { color: #FFCCBC !important; }
.text-deep-orange-200 { color: #FFAB91 !important; }
.text-deep-orange-300, .text-deep-orange-lighter { color: #FF8A65 !important; }
.text-deep-orange-400 { color: #FF7043 !important; }
.text-deep-orange-600 { color: #F4511E !important; }
.text-deep-orange-700, .text-deep-orange-darker { color: #E64A19 !important; }
.text-deep-orange-800 { color: #D84315 !important; }
.text-deep-orange-900 { color: #BF360C !important; }
.bg-gradient-deep-orange {
	background: rgb(255,138,101) !important;
	background: -moz-linear-gradient(top, rgba(255,138,101,1) 0%, rgba(230,74,25,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(255,138,101,1) 0%,rgba(230,74,25,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(255,138,101,1) 0%,rgba(230,74,25,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ff8a65', endColorstr='#e64a19',GradientType=0 ) !important;
}


/* Brown */

.bg-brown,
.bg-brown-500,
.panel-brown .panel-heading { 
    background: #795548 !important; 
}
.bg-brown-transparent-1 { background: rgba(121, 85, 72, 0.1) !important; }
.bg-brown-transparent-2 { background: rgba(121, 85, 72, 0.2) !important; }
.bg-brown-transparent-3 { background: rgba(121, 85, 72, 0.3) !important; }
.bg-brown-transparent-4 { background: rgba(121, 85, 72, 0.4) !important; }
.bg-brown-transparent-5 { background: rgba(121, 85, 72, 0.5) !important; }
.bg-brown-transparent-6 { background: rgba(121, 85, 72, 0.6) !important; }
.bg-brown-transparent-7 { background: rgba(121, 85, 72, 0.7) !important; }
.bg-brown-transparent-8 { background: rgba(121, 85, 72, 0.8) !important; }
.bg-brown-transparent-9 { background: rgba(121, 85, 72, 0.9) !important; }
.bg-brown-50 { background: #EFEBE9 !important; }
.bg-brown-100 { background: #D7CCC8 !important; }
.bg-brown-200 { background: #BCAAA4 !important; }
.bg-brown-300, .bg-brown-lighter { background: #A1887F !important; }
.bg-brown-400 { background: #8D6E63 !important; }
.bg-brown-600 { background: #6D4C41 !important; }
.bg-brown-700, .bg-brown-darker { background: #5D4037 !important; }
.bg-brown-800 { background: #4E342E !important; }
.bg-brown-900 { background: #3E2723 !important; }
.text-brown,
.text-brown-500 { 
    color: #795548 !important; 
}
a.text-brown:hover,
a.text-brown:focus { 
    color: #5D4037 !important; 
}
.text-brown-transparent-1 { color: rgba(121, 85, 72, 0.1) !important; }
.text-brown-transparent-2 { color: rgba(121, 85, 72, 0.2) !important; }
.text-brown-transparent-3 { color: rgba(121, 85, 72, 0.3) !important; }
.text-brown-transparent-4 { color: rgba(121, 85, 72, 0.4) !important; }
.text-brown-transparent-5 { color: rgba(121, 85, 72, 0.5) !important; }
.text-brown-transparent-6 { color: rgba(121, 85, 72, 0.6) !important; }
.text-brown-transparent-7 { color: rgba(121, 85, 72, 0.7) !important; }
.text-brown-transparent-8 { color: rgba(121, 85, 72, 0.8) !important; }
.text-brown-transparent-9 { color: rgba(121, 85, 72, 0.9) !important; }
.text-brown-50 { color: #EFEBE9 !important; }
.text-brown-100 { color: #D7CCC8 !important; }
.text-brown-200 { color: #BCAAA4 !important; }
.text-brown-300, .text-brown-lighter { color: #A1887F !important; }
.text-brown-400 { color: #8D6E63 !important; }
.text-brown-600 { color: #6D4C41 !important; }
.text-brown-700, .text-brown-darker { color: #5D4037 !important; }
.text-brown-800 { color: #4E342E !important; }
.text-brown-900 { color: #3E2723 !important; }
.bg-gradient-brown {
	background: rgb(161,136,127) !important;
	background: -moz-linear-gradient(top, rgba(161,136,127,1) 0%, rgba(93,64,55,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(161,136,127,1) 0%,rgba(93,64,55,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(161,136,127,1) 0%,rgba(93,64,55,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#a1887f', endColorstr='#5d4037',GradientType=0 ) !important;
}


/* Grey */

.bg-grey,
.bg-grey-500,
.bg-muted { 
    background: $grey !important; 
}
.bg-grey-transparent-1 { background: rgba(158, 158, 158, 0.1) !important; }
.bg-grey-transparent-2 { background: rgba(158, 158, 158, 0.2) !important; }
.bg-grey-transparent-3 { background: rgba(158, 158, 158, 0.3) !important; }
.bg-grey-transparent-4 { background: rgba(158, 158, 158, 0.4) !important; }
.bg-grey-transparent-5 { background: rgba(158, 158, 158, 0.5) !important; }
.bg-grey-transparent-6 { background: rgba(158, 158, 158, 0.6) !important; }
.bg-grey-transparent-7 { background: rgba(158, 158, 158, 0.7) !important; }
.bg-grey-transparent-8 { background: rgba(158, 158, 158, 0.8) !important; }
.bg-grey-transparent-9 { background: rgba(158, 158, 158, 0.9) !important; }
.bg-silver-transparent-1 { background: rgba(238, 238, 238, 0.1) !important; }
.bg-silver-transparent-2 { background: rgba(238, 238, 238, 0.2) !important; }
.bg-silver-transparent-3 { background: rgba(238, 238, 238, 0.3) !important; }
.bg-silver-transparent-4 { background: rgba(238, 238, 238, 0.4) !important; }
.bg-silver-transparent-5 { background: rgba(238, 238, 238, 0.5) !important; }
.bg-silver-transparent-6 { background: rgba(238, 238, 238, 0.6) !important; }
.bg-silver-transparent-7 { background: rgba(238, 238, 238, 0.7) !important; }
.bg-silver-transparent-8 { background: rgba(238, 238, 238, 0.8) !important; }
.bg-silver-transparent-9 { background: rgba(238, 238, 238, 0.9) !important; }
.bg-grey-50, .bg-silver-lighter { background: $silver_lighter !important; }
.bg-grey-100 { background: #F5F5F5 !important; }
.bg-grey-200, .bg-silver { background: $silver !important; }
.bg-grey-300,
.bg-grey-lighter,
.panel-default .panel-heading { 
    background: $grey_lighter !important; 
}
.bg-grey-400, .bg-silver-darker { background: $silver_darker !important; }
.bg-grey-600 { background: #757575 !important; }
.bg-grey-700, .bg-grey-darker { background: $grey_darker !important; }
.bg-grey-800 { background: #424242 !important; }
.bg-grey-900,
.panel-grey .panel-heading { 
    background: $black !important; 
}
.text-grey,
.text-muted,
.text-grey-500 { 
    color: $grey !important; 
}
a.text-grey:hover,
a.text-grey:focus { 
    color: $grey_darker !important; 
}
.text-grey-transparent-1 { color: rgba(158, 158, 158, 0.1) !important; }
.text-grey-transparent-2 { color: rgba(158, 158, 158, 0.2) !important; }
.text-grey-transparent-3 { color: rgba(158, 158, 158, 0.3) !important; }
.text-grey-transparent-4 { color: rgba(158, 158, 158, 0.4) !important; }
.text-grey-transparent-5 { color: rgba(158, 158, 158, 0.5) !important; }
.text-grey-transparent-6 { color: rgba(158, 158, 158, 0.6) !important; }
.text-grey-transparent-7 { color: rgba(158, 158, 158, 0.7) !important; }
.text-grey-transparent-8 { color: rgba(158, 158, 158, 0.8) !important; }
.text-grey-transparent-9 { color: rgba(158, 158, 158, 0.9) !important; }
.text-silver-transparent-1 { color: rgba(238, 238, 238, 0.1) !important; }
.text-silver-transparent-2 { color: rgba(238, 238, 238, 0.2) !important; }
.text-silver-transparent-3 { color: rgba(238, 238, 238, 0.3) !important; }
.text-silver-transparent-4 { color: rgba(238, 238, 238, 0.4) !important; }
.text-silver-transparent-5 { color: rgba(238, 238, 238, 0.5) !important; }
.text-silver-transparent-6 { color: rgba(238, 238, 238, 0.6) !important; }
.text-silver-transparent-7 { color: rgba(238, 238, 238, 0.7) !important; }
.text-silver-transparent-8 { color: rgba(238, 238, 238, 0.8) !important; }
.text-silver-transparent-9 { color: rgba(238, 238, 238, 0.9) !important; }
.text-grey-50, .text-silver-lighter { color: $silver_lighter !important; }
.text-grey-100 { color: #F5F5F5 !important; }
.text-grey-200, .text-silver { color: $silver !important; }
.text-grey-300, .text-grey-lighter { color: $grey_lighter !important; }
.text-grey-400, .text-silver-darker { color: $silver_darker !important; }
.text-grey-600 { color: #757575 !important; }
.text-grey-700, .text-grey-darker { color: $grey_darker !important; }
.text-grey-800 { color: #424242 !important; }
.text-grey-900 { color: $black !important; }
.bg-gradient-grey {
	background: rgb(224,224,224) !important;
	background: -moz-linear-gradient(top, rgba(224,224,224,1) 0%, rgba(97,97,97,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(224,224,224,1) 0%,rgba(97,97,97,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(224,224,224,1) 0%,rgba(97,97,97,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$grey_lighter', endColorstr='$grey_darker',GradientType=0 ) !important;
}


/* Blue Grey */

.bg-blue-grey, 
.bg-blue-grey-500 { 
    background: #607D8B !important; 
}
.bg-blue-grey-transparent-1 { background: rgba(96, 125, 139, 0.1) !important; }
.bg-blue-grey-transparent-2 { background: rgba(96, 125, 139, 0.2) !important; }
.bg-blue-grey-transparent-3 { background: rgba(96, 125, 139, 0.3) !important; }
.bg-blue-grey-transparent-4 { background: rgba(96, 125, 139, 0.4) !important; }
.bg-blue-grey-transparent-5 { background: rgba(96, 125, 139, 0.5) !important; }
.bg-blue-grey-transparent-6 { background: rgba(96, 125, 139, 0.6) !important; }
.bg-blue-grey-transparent-7 { background: rgba(96, 125, 139, 0.7) !important; }
.bg-blue-grey-transparent-8 { background: rgba(96, 125, 139, 0.8) !important; }
.bg-blue-grey-transparent-9 { background: rgba(96, 125, 139, 0.9) !important; }
.bg-blue-grey-50 { background: #ECEFF1 !important; }
.bg-blue-grey-100 { background: #CFD8DC !important; }
.bg-blue-grey-200 { background: #B0BEC5 !important; }
.bg-blue-grey-300, .bg-blue-grey-lighter { background: #90A4AE !important; }
.bg-blue-grey-400 { background: #78909C !important; }
.bg-blue-grey-600 { background: #546E7A !important; }
.bg-blue-grey-700, .bg-blue-grey-darker { background: #455A64 !important; }
.bg-blue-grey-800 { background: #37474F !important; }
.bg-blue-grey-900,
.panel-blue-grey .panel-heading { 
    background: #263238 !important; 
}
.text-blue-grey,
.text-blue-grey-500 { 
    color: #607D8B !important;
}
a.text-blue-grey:hover,
a.text-blue-grey:focus { 
    color: #455A64 !important; 
}
.text-blue-grey-transparent-1 { color: rgba(96, 125, 139, 0.1) !important; }
.text-blue-grey-transparent-2 { color: rgba(96, 125, 139, 0.2) !important; }
.text-blue-grey-transparent-3 { color: rgba(96, 125, 139, 0.3) !important; }
.text-blue-grey-transparent-4 { color: rgba(96, 125, 139, 0.4) !important; }
.text-blue-grey-transparent-5 { color: rgba(96, 125, 139, 0.5) !important; }
.text-blue-grey-transparent-6 { color: rgba(96, 125, 139, 0.6) !important; }
.text-blue-grey-transparent-7 { color: rgba(96, 125, 139, 0.7) !important; }
.text-blue-grey-transparent-8 { color: rgba(96, 125, 139, 0.8) !important; }
.text-blue-grey-transparent-9 { color: rgba(96, 125, 139, 0.9) !important; }
.text-blue-grey-50 { color: #ECEFF1 !important; }
.text-blue-grey-100 { color: #CFD8DC !important; }
.text-blue-grey-200 { color: #B0BEC5 !important; }
.text-blue-grey-300, .text-blue-grey-lighter { color: #90A4AE !important; }
.text-blue-grey-400 { color: #78909C !important; }
.text-blue-grey-600 { color: #546E7A !important; }
.text-blue-grey-700, .text-blue-grey-darker { color: #455A64 !important; }
.text-blue-grey-800 { color: #37474F !important; }
.text-blue-grey-900 { color: #263238 !important; }
.bg-gradient-blue-grey {
	background: rgb(144,164,174) !important;
	background: -moz-linear-gradient(top, rgba(144,164,174,1) 0%, rgba(69,90,100,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(144,164,174,1) 0%,rgba(69,90,100,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(144,164,174,1) 0%,rgba(69,90,100,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#90a4ae', endColorstr='#455a64',GradientType=0 ) !important;
}


/* Black */

.bg-black,
.bg-inverse,
.panel-black .panel-heading,
.panel-inverse .panel-heading { 
    background: $black !important;
}
.bg-black-lighter { background: $black !important; }
.bg-black-darker { background: $black_darker !important; }
.bg-black-transparent-1 { background: rgba(0, 0, 0, 0.1) !important; }
.bg-black-transparent-2 { background: rgba(0, 0, 0, 0.2) !important; }
.bg-black-transparent-3 { background: rgba(0, 0, 0, 0.3) !important; }
.bg-black-transparent-4 { background: rgba(0, 0, 0, 0.4) !important; }
.bg-black-transparent-5 { background: rgba(0, 0, 0, 0.5) !important; }
.bg-black-transparent-6 { background: rgba(0, 0, 0, 0.6) !important; }
.bg-black-transparent-7 { background: rgba(0, 0, 0, 0.7) !important; }
.bg-black-transparent-8 { background: rgba(0, 0, 0, 0.8) !important; }
.bg-black-transparent-9 { background: rgba(0, 0, 0, 0.9) !important; }
.text-black,
.text-black-darker,
.text-inverse {
    color: $black_darker !important;
}
.text-black-lighter { color: $black !important; }
.text-black-transparent-1 { color: rgba(0, 0, 0, 0.1) !important; }
.text-black-transparent-2 { color: rgba(0, 0, 0, 0.2) !important; }
.text-black-transparent-3 { color: rgba(0, 0, 0, 0.3) !important; }
.text-black-transparent-4 { color: rgba(0, 0, 0, 0.4) !important; }
.text-black-transparent-5 { color: rgba(0, 0, 0, 0.5) !important; }
.text-black-transparent-6 { color: rgba(0, 0, 0, 0.6) !important; }
.text-black-transparent-7 { color: rgba(0, 0, 0, 0.7) !important; }
.text-black-transparent-8 { color: rgba(0, 0, 0, 0.8) !important; }
.text-black-transparent-9 { color: rgba(0, 0, 0, 0.9) !important; }
.bg-gradient-black {
	background: rgb(102,102,102) !important;
	background: -moz-linear-gradient(top, rgba(102,102,102,1) 0%, rgba(0,0,0,1) 100%) !important;
	background: -webkit-linear-gradient(top, rgba(102,102,102,1) 0%,rgba(0,0,0,1) 100%) !important;
	background: linear-gradient(to bottom, rgba(102,102,102,1) 0%,rgba(0,0,0,1) 100%) !important;
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#666666', endColorstr='$black_darker000',GradientType=0 ) !important;
}


/* White */

.bg-white,
.panel-white .panel-heading { 
    background: #fff !important;
}
.bg-white-transparent-1 { background: rgba(255, 255, 255, 0.1) !important; }
.bg-white-transparent-2 { background: rgba(255, 255, 255, 0.2) !important; }
.bg-white-transparent-3 { background: rgba(255, 255, 255, 0.3) !important; }
.bg-white-transparent-4 { background: rgba(255, 255, 255, 0.4) !important; }
.bg-white-transparent-5 { background: rgba(255, 255, 255, 0.5) !important; }
.bg-white-transparent-6 { background: rgba(255, 255, 255, 0.6) !important; }
.bg-white-transparent-7 { background: rgba(255, 255, 255, 0.7) !important; }
.bg-white-transparent-8 { background: rgba(255, 255, 255, 0.8) !important; }
.bg-white-transparent-9 { background: rgba(255, 255, 255, 0.9) !important; }
.text-white {
    color: #fff !important;
}
.text-white-transparent-1 { color: rgba(255, 255, 255, 0.1) !important; }
.text-white-transparent-2 { color: rgba(255, 255, 255, 0.2) !important; }
.text-white-transparent-3 { color: rgba(255, 255, 255, 0.3) !important; }
.text-white-transparent-4 { color: rgba(255, 255, 255, 0.4) !important; }
.text-white-transparent-5 { color: rgba(255, 255, 255, 0.5) !important; }
.text-white-transparent-6 { color: rgba(255, 255, 255, 0.6) !important; }
.text-white-transparent-7 { color: rgba(255, 255, 255, 0.7) !important; }
.text-white-transparent-8 { color: rgba(255, 255, 255, 0.8) !important; }
.text-white-transparent-9 { color: rgba(255, 255, 255, 0.9) !important; }


.text-gradient {
	-webkit-background-clip: text !important;
	-webkit-text-fill-color: transparent !important;
}
.no-bg, .bg-none { background: none !important; }

.height-xs { height: 150px !important; }
.height-sm { height: 300px !important; }
.height-md { height: 450px !important; }
.height-lg { height: 600px !important; }
.height-full { height: 100% !important; }
.height-0 { height: 0px !important; }
.height-10 { height: 10px !important; }
.height-20 { height: 20px !important; }
.height-30 { height: 30px !important; }
.height-40 { height: 40px !important; }
.height-50 { height: 50px !important; }
.height-60 { height: 60px !important; }
.height-70 { height: 70px !important; }
.height-80 { height: 80px !important; }
.height-90 { height: 90px !important; }
.height-100 { height: 100px !important; }
.height-150 { height: 150px !important; }
.height-200 { height: 200px !important; }
.height-250 { height: 250px !important; }
.height-300 { height: 300px !important; }
.height-350 { height: 350px !important; }
.height-400 { height: 400px !important; }
.height-450 { height: 450px !important; }
.height-500 { height: 500px !important; }
.height-550 { height: 550px !important; }
.height-600 { height: 600px !important; }

.width-xs { width: 150px !important; }
.width-sm { width: 300px !important; }
.width-md { width: 450px !important; }
.width-lg { width: 600px !important; }
.width-full { width: 100% !important; }
.width-0 { width: 0px !important; }
.width-10 { width: 10px !important; }
.width-20 { width: 20px !important; }
.width-30 { width: 30px !important; }
.width-40 { width: 40px !important; }
.width-50 { width: 50px !important; }
.width-60 { width: 60px !important; }
.width-70 { width: 70px !important; }
.width-80 { width: 80px !important; }
.width-90 { width: 90px !important; }
.width-100 { width: 100px !important; }
.width-150 { width: 150px !important; }
.width-200 { width: 200px !important; }
.width-250 { width: 250px !important; }
.width-300 { width: 300px !important; }
.width-350 { width: 350px !important; }
.width-400 { width: 400px !important; }
.width-450 { width: 450px !important; }
.width-500 { width: 500px !important; }
.width-550 { width: 550px !important; }
.width-600 { width: 600px !important; }

.pointer-cursor { cursor: pointer !important; }
.cursor-pointer { cursor: pointer !important; }
.cursor-initial { cursor: initial !important; }
.cursor-move { cursor: move !important; }

.animated {
    -webkit-animation-duration: .6s;
    animation-duration: .6s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
}
.fade {
    opacity: 0;
    transition: opacity .3s linear;
}
.fade.show {
	opacity: 1.0;
}
.text-ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}
.text-nowrap {
    white-space: nowrap !important;
}
.underline {
    border-bottom: 1px solid #e2e7eb !important;
}
.with-shadow {
	box-shadow: 0 12px 36px 6px rgba(0,0,0,0.1) !important;
}