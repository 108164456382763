/* Pages - 404 Error */

.error {
    margin: 0 auto;
    text-align: center;
}
.error-code,
.error-content {
    position: absolute;
    left: 0;
    right: 0;
    -webkit-transform: translateZ(0);
    transform: translateZ(0);
}
.error-code {
    bottom: 45%;
    color: $black;
    font-size: 200px;
    line-height: 200px;
    text-shadow: 10px 10px 0px rgba(0,0,0,0.1);
}
.error-content {
    top: 55%;
    bottom: 0;
    padding-top: 25px;
    background: $black;
    background-image: url(images/error-page-pattern.png);
}
.error-message {
    color: #fff;
    font-size: 24px;
    letter-spacing: 0.5px;
    margin-bottom: 5px;
}
.error-desc {
    font-size: 13px;
    color: #fff;
    color: rgba(255,255,255,0.5);
    letter-spacing: 1px;
}