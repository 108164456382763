/* Component - Badge & Label Setting */

.badge {
    font-size: 75%;
    line-height: 1.25;
    font-weight: 600;
    display: inline-block;
    min-width: 10px;
    padding: 3px 7px;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    background-color: #777;
    border-radius: 10px;
}
.label {
	display: inline;
	padding: .2em .6em .3em;
	font-size: 75%;
	font-weight: 600;
	line-height: 1;
	color: #fff;
	text-align: center;
	white-space: nowrap;
	vertical-align: baseline;
	border-radius: .25em;
}
.badge.badge-square { 
	border-radius: 0; 
}
.badge.badge-default, 
.label.label-default { background: #f5f5f5; color: $black; }
.badge.badge-danger, 
.label.label-danger { background: $red; }
.badge.badge-warning, 
.label.label-warning { background: $orange; }
.badge.badge-success, 
.label.label-success,
.badge.badge-teal, 
.label.label-teal { background: $teal; }
.badge.badge-info, 
.label.label-info { background: $aqua; }
.badge.badge-primary, 
.label.label-primary { background: $blue; }
.badge.badge-secondary,
.label.label-secondary { background: $silver_darker; }
.badge.badge-lime,
.label.label-lime { background: $green; }
.badge.badge-yellow,
.label.label-yellow { background: $yellow; color: $black_darker; }
.badge.badge-purple,
.label.label-purple { background: $purple; }
.badge.badge-light,
.label.label-light { background: #fff; color: #666; }
.badge.badge-inverse, 
.label.label-inverse,
.badge.badge-dark, 
.label.label-dark { background: $black; }
.badge.badge-pink, 
.label.label-pink { background: $pink; }
.badge.badge-indigo, 
.label.label-indigo { background: $indigo; }
.badge.badge-green,
.label.label-green { background: #4CAF50; }