/* Plugin - NVD3 */

.nvtooltip {
    font-family: inherit !important;
    font-size: 12px !important;
    border: none !important;
    padding: 5px 10px !important;
	border-radius: 8px !important;
	background: rgba(255, 255, 255, 0.95) !important;
	box-shadow: 0 5px 30px 0 rgba(0,0,0,.25) !important;
	border: none !important;
}
.nvtooltip table td.legend-color-guide div {
    border: none !important;
	border-radius: 4px !important;
}
.nvtooltip table thead strong {
    text-decoration: underline !important;
}
.nvd3-inverse-mode .nvd3 line.nv-guideline {
	stroke: rgba(255,255,255,0.5) !important;
}
.nvd3-inverse-mode .nvd3 .nv-bars text {
	fill: rgba(255,255,255, 1) !important;
}
.nvd3-inverse-mode .nvd3 .nv-axis line,
.nvd3-inverse-mode .nvd3 .nv-axis path {
	fill: none;
	stroke: rgba(255,255,255,0.15) !important;
}
.nvd3-inverse-mode .nvd3 .nv-legend-text,
.nvd3-inverse-mode .nvd3 .nv-x text,
.nvd3-inverse-mode .nvd3 .nv-y text {
	font-family: inherit !important;
	fill: rgba(255,255,255,0.75) !important;
	font-size: 11px !important;
	font-weight: 500 !important;
}
.nvd3-inverse-mode .nvd3.nv-pie .nv-pie-title,
.nvd3-inverse-mode .nv-label text {
	fill: rgba(255,255,255,0.75) !important;
}
.nvd3-inverse-mode .nvd3 .nv-legend-symbol {
	r: 3;
}
.nvd3-inverse-mode .nvd3.nv-pie path {
	stroke: rgba(0,0,0,0.2) !important;
}