/* Theme - Default */

.navbar-logo,
.coming-soon .brand .logo,
.login .login-header .brand .logo {
    border-color: $aqua_lighter $aqua $aqua_darker;
}
.sidebar .nav > li.active > a, 
.sidebar .nav > li.active > a:focus, 
.sidebar .nav > li.active > a:hover,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a, 
.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a, 
.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.label.label-theme,
.btn.btn-scroll-to-top {
    background: $aqua;
}
.navbar.navbar-inverse .navbar-brand {
    color: #fff !important;
}
.text-theme,
.sidebar .sub-menu > li.active > a:before {
    color: $aqua;
}
.gradient-enabled .navbar.navbar-inverse .navbar-brand,
.gradient-enabled .sidebar .nav > li.active > a, 
.gradient-enabled .sidebar .nav > li.active > a:focus, 
.gradient-enabled .sidebar .nav > li.active > a:hover,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a, 
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a, 
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.gradient-enabled .label.label-theme,
.gradient-enabled .btn.btn-scroll-to-top,
.gradient-enabled .top-menu .nav > li.active > a, 
.gradient-enabled .top-menu .nav > li.active > a:focus, 
.gradient-enabled .top-menu .nav > li.active > a:hover {
    background: $aqua_lighter; /* Old browsers */
    background: -moz-linear-gradient(top,  $aqua_lighter 0%, #00acc1 100%); /* FF3.6-15 */
    background: -webkit-linear-gradient(top,  $aqua_lighter 0%,#00acc1 100%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(to bottom,  $aqua_lighter 0%,#00acc1 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$aqua_lighter', endColorstr='#00acc1',GradientType=0 ); /* IE6-9 */
}
.pace-progress {
    background: $aqua;
}
.pace .pace-activity {
    border-top-color: $aqua;
    border-left-color: $aqua;
}
.page-with-light-sidebar .sidebar .nav > li.active > a > i {
    color: #fff;
}
.page-with-light-sidebar .sidebar .nav > li > a .badge,
.page-with-light-sidebar .sidebar .nav > li > a .label.label-theme,
.page-with-light-sidebar .sidebar .nav > li.active > a .badge,
.page-with-light-sidebar .sidebar .nav > li.active > a .label.label-theme {
    background: $pink;
}
.page-with-light-sidebar .sidebar .text-theme {
    color: $pink;
}
.top-menu .nav > li.active > a, 
.top-menu .nav > li.active > a:focus, 
.top-menu .nav > li.active > a:hover {
    background: $aqua;
}