/* Plugin - Typeahead (Added in V1.2) */

.twitter-typeahead .tt-hint {
    line-height: 22px;
    padding: 5px 11px;
    opacity: 0.6;
}
.tt-suggestion.tt-is-under-cursor {
    background: #edf0f5;
    color: #222;
}