/* Pages - Timeline */

.timeline {
    list-style-type: none;
    margin: 0;
    padding: 0;
    position: relative;
}
.timeline:before {
    content: '';
    position: absolute;
    top: 5px;
    bottom: 5px;
    width: 5px;
    background: $black;
    left: 20%;
    margin-left: -2.5px;
}
.timeline > li {
    position: relative;
    min-height: 50px;
    padding: 20px 0;
}
.timeline .timeline-time {
    position: absolute;
    left: 0;
    width: 18%;
    text-align: right;
    top: 30px;
}
.timeline .timeline-time .date,
.timeline .timeline-time .time {
    display: block;
    font-weight: 600;
}
.timeline .timeline-time .date {
    line-height: 16px;
    font-size: 13px;
    font-weight: 500;
}
.timeline .timeline-time .time {
    line-height: 24px;
    font-size: 20px;
    color: $black_darker;
}
.timeline .timeline-icon {
    left: 15%;
    position: absolute;
    width: 10%;
    text-align: center;
    top: 40px;
}
.timeline .timeline-icon a {
    text-decoration: none;
    width: 20px;
    height: 20px;
    display: inline-block;
    border-radius: 20px;
    background: #d9e0e7;
    line-height: 10px;
    color: #fff;
    font-size: 14px;
    border: 5px solid $black;
    transition: border-color .2s linear;
}
.timeline .timeline-body {
    margin-left: 23%;
    margin-right: 17%;
    background: #fff;
    position: relative;
    padding: 20px 25px;
    border-radius: 6px;
}
.timeline .timeline-body:before {
    content: '';
    display: block;
    position: absolute;
    border: 10px solid transparent;
    border-right-color: #fff;
    left: -20px;
    top: 20px;
}
.timeline .timeline-body > div + div {
	margin-top: 15px;
}
.timeline .timeline-body > div + div:last-child {
	margin-bottom: -20px;
	padding-bottom: 20px;
	border-radius: 0 0 6px 6px;
}
.timeline-header {
    padding-bottom: 10px;
    border-bottom: 1px solid #e2e7eb;
    line-height: 30px;
}
.timeline-header .userimage {
    float: left;
    width: 34px;
    height: 34px;
    border-radius: 40px;
    overflow: hidden;
    margin: -2px 10px -2px 0;
}
.timeline-header .username {
    font-size: 16px;
    font-weight: 500;
}
.timeline-header .username,
.timeline-header .username a {
    color: $black;
}
.timeline img {
    max-width: 100%;
    display: block;
}
.timeline-content {
	letter-spacing: 0.25px;
	line-height: 18px;
	font-size: 13px;
}
.timeline-content:before,
.timeline-content:after {
    content: '';
    display: table;
    clear: both;
}
.timeline-title {
    margin-top: 0;
}
.timeline-footer {
    background: #fff;
    border-top: 1px solid #e2e7ec;
    padding-top: 15px;
}
.timeline-footer a:not(.btn) {
    color: #575d63;
}
.timeline-footer a:not(.btn):hover,
.timeline-footer a:not(.btn):focus {
    color: $black;
}
.timeline-likes {
	color: #6d767f;
	font-weight: 600;
	font-size: 13px;
}
.timeline-likes .stats-right {
	float: right;
}
.timeline-likes .stats-total {
	display: inline-block;
	line-height: 20px;
}
.timeline-likes .stats-icon {
	float: left;
	margin-right: 5px;
	font-size: 9px;
}
.timeline-likes .stats-icon + .stats-icon {
	margin-left: -2px;
}
.timeline-likes .stats-text {
	line-height: 20px;
}
.timeline-likes .stats-text + .stats-text {
	margin-left: 15px;
}
.timeline-comment-box {
	background: #f5f5f5;
	margin-left: -25px;
	margin-right: -25px;
	padding: 20px 25px;
}
.timeline-comment-box .user {
	float: left;
	width: 34px;
	height: 34px;
	overflow: hidden;
	border-radius: 30px;
}
.timeline-comment-box .user img {
	max-width: 100%;
	max-height: 100%;
}
.timeline-comment-box .user + .input {
	margin-left: 44px;
}