/* Plugin - Wysihtml5 Sandbox */

.wysihtml5-sandbox {
    width: 100% !important;
}
.panel-form .wysihtml5-toolbar {
    padding: 15px 15px 5px;
}
.panel-form .wysihtml5-sandbox {
    border-radius: 0 0 4px 4px !important;
}
.wysihtml5-toolbar .glyphicon-quote:before { display: none; }
.wysihtml5-toolbar .glyphicon-quote:after { content: '\f10d'; }
.wysihtml5-toolbar .glyphicon-list:before { content: '\f03a'; }
.wysihtml5-toolbar .glyphicon-indent-right:before { content: '\f038'; }
.wysihtml5-toolbar .glyphicon-indent-left:before { content: '\f036'; }
.wysihtml5-toolbar .glyphicon-th-list:before { content: '\f00b'; }
.wysihtml5-toolbar .glyphicon-share:before { content: '\f064'; }
.wysihtml5-toolbar .glyphicon-picture:before { content: '\f03e'; }
.wysihtml5-toolbar .dropdown-toggle:after { display: none; }
