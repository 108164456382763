/* Component - Code */

code {
    padding: 2px 4px;
    font-size: 90%;
    color: #e83e8c;
    background-color: #fff2f6;
    border-radius: 4px;
}
.inverse-mode code,
code.inverse-mode {
    background: #2e353c;
    color: #ffd900;
}