/* Plugin - Bootstrap SweetAlert */

.swal-modal .btn:focus {
	box-shadow: none !important;
}
.swal-icon--success,
.swal-icon--success__ring {
	border-color: rgba(0, 150, 136, 0.2) !important;
}
.swal-icon--success__line--long,
.swal-icon--success__line--tip {
	background-color: $teal !important;
}
.swal-icon--info {
	border-color: $aqua !important;
}
.swal-icon--info:after, 
.swal-icon--info:before {
	background-color: $aqua !important;
}
.swal-icon--warning__body, 
.swal-icon--warning__dot {
	background-color: $orange !important;
}
.swal-icon--warning {
	border-color: $orange !important;
}
.swal-icon--error__line {
	background-color: $red !important;
}
.swal-icon--error {
	border-color: $red !important;
}