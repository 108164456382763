/* Plugin - Select 2 Choice */

.select2-container.form-control .select2-choice, 
.select2-container.form-control .select2-choice {
    background: none !important;
    margin: -6px -12px !important;
    padding: 6px 12px !important;
    line-height: 20px !important;
    border:none !important;
    box-shadow: none !important;
    height: 32px !important;
}
.select2-container.form-control.input-sm .select2-choice, 
.select2-container.form-control.input-sm .select2-choice {
    height: 30px !important;
}
.select2-container .select2-choice .select2-arrow {
    background: none !important;
    border-left: none !important;
    width: 22px !important;
}
.select2-container.form-control .select2-choice abbr {
    top: 10px !important;
}
.select2-container.form-control.input-sm .select2-choice abbr {
    top: 10px !important;
}
.select2-container.form-control.input-sm .select2-choice .select2-arrow b {
    background-position: -3px 4px !important;
}
.select2-container .select2-choice .select2-arrow b {
    background-position: 0 3px !important;
}
.select2-drop-active {
    border-color: #e2e7eb !important;
    box-shadow: 0 2px 2px -1px rgba(0,0,0,0.2) !important;
}
.select2-drop.select2-drop-above {
    box-shadow: 0 -2px 2px -1px rgba(0,0,0,0.2) !important;
}
.select2-search {
	padding: 5px !important;
}
.select2-search input {
    border-color: #e2e7eb !important;
    border-radius: 4px !important;
}
.select2-drop {
    border: none !important;
    border-top: 1px solid #d3d8df !important;
    box-shadow: 0 10px 30px 0px rgba(0,0,0,.2) !important;
    margin-top: -2px !important;
}
.select2-results {
	margin: 0 !important;
	padding: 5px 0 !important;
}
.select2-results .select2-result-label {
	padding: 5px 15px !important;
}
.select2-results .select2-highlighted {
    background: #f5f5f5 !important;
    color: #333 !important;
}
.select2-container.select2-container-multi.form-control {
    height: auto !important;
    width: 240px !important;
}
.select2-container.select2-container-multi.form-control .select2-choices {
    margin: -6px -12px !important;
    border: none !important;
    box-shadow: none !important;
    min-height: 22px !important;
    height: auto !important;
    background: none !important;
}
.select2-container.select2-container-multi.form-control.select2-container-active {
	border-color: #5db0ff !important;
    outline: 0;
    box-shadow: 0 0 0 0.125rem rgba(52, 142, 227, 0.3);
}
.select2-container-multi .select2-choices .select2-search-choice {
    box-shadow: none !important;
    border-color: #f5f5f5 !important;
    background: #f5f5f5 !important;
    margin-top: 4px !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li {
	padding: 0 !important;
}
.select2-container--default .select2-selection--multiple .select2-selection__rendered li.select2-selection__choice {
	padding: 0 5px !important;
}