/* Component - Jumbotron */

.jumbotron {
    background: #F5F5F5;
    border-radius: 6px;
    
	& h1, 
	& .h1 {
		font-size: 56px;
	}
	& p {
		font-size: 18px;
	}
}