/* Plugin - Highlight JS */

.hljs-wrapper {
    background: #F5F5F5;
    border-radius: 3px;
    position: relative;
}
.hljs-wrapper pre {
	border: none;
	padding: 0;
	background: none;
	border-radius: 0;
}
.hljs-wrapper .hljs {
	padding: 15px;
	border-radius: 0;
    display: block;
    overflow-x: auto;
    color: #222;
    border: none;
	font-size: 13px;
	background: none;
	border-radius: 0;
}
.hljs-wrapper .hljs-tag,
.hljs-wrapper .hljs-section, 
.hljs-wrapper .hljs-name, 
.hljs-wrapper .hljs-selector-tag, 
.hljs-wrapper .hljs-deletion, 
.hljs-wrapper .hljs-subst {
    color: #2b71b5;
}
.hljs-wrapper .hljs-attr, 
.hljs-wrapper .hljs-variable, 
.hljs-wrapper .hljs-template-variable, 
.hljs-wrapper .hljs-type, 
.hljs-wrapper .hljs-selector-class, 
.hljs-wrapper .hljs-selector-attr, 
.hljs-wrapper .hljs-selector-pseudo, 
.hljs-wrapper .hljs-number {
    color: #4ea2d4;
}
.hljs-wrapper .hljs-string, 
.hljs-wrapper .hljs-regexp, 
.hljs-wrapper .hljs-addition, 
.hljs-wrapper .hljs-attribute,
.hljs-wrapper .hljs-meta-string {
    color: #f44236;
}
.hljs-comment {
	color: #728088;
}
.panel-body + .hljs-wrapper {
	border-radius: 0 0 3px 3px;
}
.hljs-wrapper .btn-clipboard {
	position: absolute;
	right: 10px;
	top: 10px;
}
.modal .hljs-wrapper {
	border-radius: 0;
	margin-top: -1px;
}
.modal .hljs-wrapper + .modal-footer {
	border-top: none;
}