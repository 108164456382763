/* Theme - Manager */
@import '_variables';


.navbar-logo, .coming-soon .brand .logo, .login .login-header .brand .logo { border-color: $red_lighter $red $red_darker; }
.sidebar .nav > li.active > a,
.sidebar .nav > li.active > a:focus,
.sidebar .nav > li.active > a:hover,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a,
.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a,
.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.label.label-theme,
.btn.btn-scroll-to-top, .btn.btn-scroll-to-top:active {
  background: $red !important;
}
.navbar.navbar-inverse .navbar-brand { color: #fff !important; }
.text-theme, .sidebar .sub-menu > li.active > a:before { color: $red; }
@media (max-width: 767px) {
  .navbar-inverse .navbar-header { background: $red; }
}
.gradient-enabled .navbar.navbar-inverse .navbar-brand,
.gradient-enabled .sidebar .nav > li.active > a,
.gradient-enabled .sidebar .nav > li.active > a:focus,
.gradient-enabled .sidebar .nav > li.active > a:hover,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:focus > a,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active:hover > a,
.gradient-enabled.page-sidebar-minified .sidebar .nav > li.has-sub.active > a,
.gradient-enabled .label.label-theme,
.gradient-enabled .btn.btn-scroll-to-top,
.gradient-enabled .top-menu .nav > li.active > a,
.gradient-enabled .top-menu .nav > li.active > a:focus,
.gradient-enabled .top-menu .nav > li.active > a:hover {
  background: $red_lighter !important; /* Old browsers */
  background: -moz-linear-gradient(top,  $red 0%, $red_darker 100%) !important; /* FF3.6-15 */
  background: -webkit-linear-gradient(top,  $red 0%,$red_darker 100%) !important; /* Chrome10-25,Safari5.1-6 */
  background: linear-gradient(to bottom,  $red 0%,$red_darker 100%) !important; /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='$red', endColorstr='$red_darker',GradientType=0 ) !important; /* IE6-9 */
}
.pace-progress { background: $red; }
.pace .pace-activity { border-top-color: $red; border-left-color: $red; }
.page-with-light-sidebar .sidebar .nav > li.active > a > i { color: $red; }
.page-with-light-sidebar .sidebar .nav > li.active > a .badge,
.page-with-light-sidebar .sidebar .nav > li.active > a .label.label-theme {
  background: $red;
}
.top-menu .nav > li.active > a,
.top-menu .nav > li.active > a:focus,
.top-menu .nav > li.active > a:hover {
  background: $red;
}
.spinner-small { border-top-color: $red !important; }

/* Color admin v4.7 */
.bg-blue-dark {
    background-color: #263238!important
}

.bg-blue-dark-lighter {
    background-color: #5c656a!important
}

.bg-blue-dark-darker {
    background-color: #1d262a!important
}

.bg-gradient-blue-dark {
    background: #263238!important;
    background: -moz-linear-gradient(to bottom,#263838 0,#263138 100%)!important;
    background: -webkit-linear-gradient(to bottom,#263838 0,#263138 100%)!important;
    background: linear-gradient(to bottom,#263838 0,#263138 100%)!important
}

.app-theme-blue-dark .app-sidebar .nav>li.active>a {
    background-color: #263238!important
}

.bg-blue-dark-transparent-1 {
    background-color: rgba(38,50,56,.1)!important
}

.text-blue-dark-transparent-1 {
    color: rgba(38,50,56,.1)!important
}

.bg-blue-dark-transparent-2 {
    background-color: rgba(38,50,56,.2)!important
}

.text-blue-dark-transparent-2 {
    color: rgba(38,50,56,.2)!important
}

.bg-blue-dark-transparent-3 {
    background-color: rgba(38,50,56,.3)!important
}

.text-blue-dark-transparent-3 {
    color: rgba(38,50,56,.3)!important
}

.bg-blue-dark-transparent-4 {
    background-color: rgba(38,50,56,.4)!important
}

.text-blue-dark-transparent-4 {
    color: rgba(38,50,56,.4)!important
}

.bg-blue-dark-transparent-5 {
    background-color: rgba(38,50,56,.5)!important
}

.text-blue-dark-transparent-5 {
    color: rgba(38,50,56,.5)!important
}

.bg-blue-dark-transparent-6 {
    background-color: rgba(38,50,56,.6)!important
}

.text-blue-dark-transparent-6 {
    color: rgba(38,50,56,.6)!important
}

.bg-blue-dark-transparent-7 {
    background-color: rgba(38,50,56,.7)!important
}

.text-blue-dark-transparent-7 {
    color: rgba(38,50,56,.7)!important
}

.bg-blue-dark-transparent-8 {
    background-color: rgba(38,50,56,.8)!important
}

.text-blue-dark-transparent-8 {
    color: rgba(38,50,56,.8)!important
}

.bg-blue-dark-transparent-9 {
    background-color: rgba(38,50,56,.9)!important
}

.text-blue-dark-transparent-9 {
    color: rgba(38,50,56,.9)!important
}

.text-dark {
    color: #212121!important
}

.text-dark-lighter {
    color: #595959!important
}

.text-dark-darker {
    color: #191919!important
}
