/* Component - Carousel */

.carousel .carousel-control .fa {
    position: absolute;
    top: 50%;
    z-index: 5;
    display: block;
    width: 30px;
    height: 30px;
    margin-top: -15px;
    text-align: center;
    line-height: 30px;
    margin-left: -15px;
}
.carousel .carousel-control.left .fa {
    margin-left: 15px;
}