/* Plugin - Slimscrollbar */

.slimScrollBar {
    width: 6px !important;
    right: 2px !important;
}
.slimScrollRail {
    width: 6px !important;
    right: 2px !important;
}
.sidebar .slimScrollBar,
.content-inverse-mode .slimScrollBar,
.inverse-mode .slimScrollBar {
	background: rgba(255,255,255,0.5) !important;
}
