/* Component - Table */

.table {
    border-color: $grey_lighter;
    border-radius: 3px;
    background: none;
}
.table.table-inverse {
    background: none;
}
.table thead th,
.table > thead > tr > th {
    color: $black_darker;
    font-weight: 600;
    border-bottom: 1px solid $silver_darker !important;
}
.table > thead > tr > th, 
.table > tbody > tr > th, 
.table > tfoot > tr > th, 
.table > thead > tr > td, 
.table > tbody > tr > td, 
.table > tfoot > tr > td {
    border-color: $grey_lighter;
    padding: 10px 15px;
}
.table-condensed > thead > tr > th, 
.table-condensed > tbody > tr > th, 
.table-condensed > tfoot > tr > th, 
.table-condensed > thead > tr > td, 
.table-condensed > tbody > tr > td, 
.table-condensed > tfoot > tr > td {
    padding: 7px 15px;
}
.table-hover > tbody > tr:hover > td, 
.table-hover > tbody > tr:hover > th {
    background: #eee !important;
}
.table-striped > tbody > tr:nth-child(odd) > td, 
.table-striped > tbody > tr:nth-child(odd) > th {
    background: #F5F5F5;
}
.table.table-inverse {
	color: rgba(255, 255, 255, 0.75);
}
.table.table-inverse > thead > tr > th, 
.table.table-inverse > tbody > tr > th, 
.table.table-inverse > tfoot > tr > th {
    color: #fff;
}
.flat-black .table.table-inverse > thead > tr > th,
.table.table-inverse > thead > tr > th, 
.table.table-inverse > tbody > tr > th, 
.table.table-inverse > tfoot > tr > th, 
.table.table-inverse > thead > tr > td, 
.table.table-inverse > tbody > tr > td, 
.table.table-inverse > tfoot > tr > td {
    border-color: #444 !important;
    border-color: rgba(255, 255, 255, 0.15) !important;
    background: none !important;
}
.table > thead > tr > td.info, 
.table > tbody > tr > td.info, 
.table > tfoot > tr > td.info, 
.table > thead > tr > th.info, 
.table > tbody > tr > th.info, 
.table > tfoot > tr > th.info, 
.table > thead > tr.info > td, 
.table > tbody > tr.info > td, 
.table > tfoot > tr.info > td, 
.table > thead > tr.info > th, 
.table > tbody > tr.info > th, 
.table > tfoot > tr.info > th {
    background: #c0e9fc;
    border-color: #027fb7;
    color: #012a3d;
}
.table > thead > tr > td.success, 
.table > tbody > tr > td.success, 
.table > tfoot > tr > td.success, 
.table > thead > tr > th.success, 
.table > tbody > tr > th.success, 
.table > tfoot > tr > th.success, 
.table > thead > tr.success > td, 
.table > tbody > tr.success > td, 
.table > tfoot > tr.success > td, 
.table > thead > tr.success > th, 
.table > tbody > tr.success > th, 
.table > tfoot > tr.success > th {
    background: #bfe5e1;
    border-color: #007066;
    color: #004b44;
}
.table > thead > tr > td.danger, 
.table > tbody > tr > td.danger, 
.table > tfoot > tr > td.danger, 
.table > thead > tr > th.danger, 
.table > tbody > tr > th.danger, 
.table > tfoot > tr > th.danger, 
.table > thead > tr.danger > td, 
.table > tbody > tr.danger > td, 
.table > tfoot > tr.danger > td, 
.table > thead > tr.danger > th, 
.table > tbody > tr.danger > th, 
.table > tfoot > tr.danger > th {
    background: #fcd0cd;
    border-color: #b73228;
    color: #3d110e;
}
.table > thead > tr > td.warning, 
.table > tbody > tr > td.warning, 
.table > tfoot > tr > td.warning, 
.table > thead > tr > th.warning, 
.table > tbody > tr > th.warning, 
.table > tfoot > tr > th.warning, 
.table > thead > tr.warning > td, 
.table > tbody > tr.warning > td, 
.table > tfoot > tr.warning > td, 
.table > thead > tr.warning > th, 
.table > tbody > tr.warning > th, 
.table > tfoot > tr.warning > th {
    background: #ffe5bf;
    border-color: #bf7200;
    color: #402600;
}
.table > thead > tr > td.active, 
.table > tbody > tr > td.active, 
.table > tfoot > tr > td.active, 
.table > thead > tr > th.active, 
.table > tbody > tr > th.active, 
.table > tfoot > tr > th.active, 
.table > thead > tr.active > td, 
.table > tbody > tr.active > td, 
.table > tfoot > tr.active > td, 
.table > thead > tr.active > th, 
.table > tbody > tr.active > th, 
.table > tfoot > tr.active > th {
    background: #e7e7e7;
    border-color: #767676;
    color: #282828;
}
.table > caption+thead > tr:first-child > td, 
.table > caption+thead > tr:first-child > th, 
.table > colgroup+thead > tr:first-child > td, 
.table > colgroup+thead > tr:first-child > th, 
.table > thead:first-child > tr:first-child > td, 
.table > thead:first-child > tr:first-child > th {
	border-top: 0;
}
.table.table-bordered > thead:first-child > tr:first-child > td, 
.table.table-bordered > thead:first-child > tr:first-child > th {
	border-top: 1px solid $grey_lighter;
}
.table .with-form-control .form-control,
.table .with-input-group .input-group {
	margin-top: -8px;
	margin-bottom: -8px;
}
.table-condensed .with-form-control .form-control,
.table-condensed .with-input-group .input-group {
	margin-top: -6px;
	margin-bottom: -5px;
}
.table .with-img img,
.table .with-btn .btn,
.table .with-btn-group .btn-group {
	margin-top: -8px;
	margin-bottom: -7px;
}
.table .with-checkbox .checkbox,
.table .with-radio .radio {
	margin: 0;
	padding: 0;
}
.table .with-checkbox .checkbox-css label,
.table .with-radio .radio-css label {
	min-height: inherit;
}
.table .with-checkbox .checkbox-css input + label:before,
.table .with-checkbox .checkbox-css input:checked + label:after,
.table .with-radio .radio-css input + label:before {
	top: 0;
}
.table .with-radio .radio-css input:checked + label:after {
	top: 5px;
}