/* RTL Settings */

.rtl-mode {
	direction: rtl;
	text-align: right;
}
.rtl-mode .header .navbar-header, 
.rtl-mode .header .navbar-nav > li,
.rtl-mode .header .navbar-logo {
	float: right;
}
.rtl-mode .header .navbar-logo {
	margin-left: 10px;
	margin-right: 0;
}
.rtl-mode .header .navbar-right,
.rtl-mode .pull-right {
	float: left !important;
}
.rtl-mode .header .navbar-toggle.navbar-toggle-left {
	float: right;
	margin-left: -15px;
	margin-right: 0;
}
.rtl-mode .text-right {
	text-align: left !important;
}
.rtl-mode .text-left {
	text-align: right !important;
}
.rtl-mode .header .navbar-left,
.rtl-mode .pull-left {
	float: right !important;
}
.rtl-mode #gritter-notice-wrapper {
	left: 20px;
	right: auto;
}
.rtl-mode .header .navbar-form .btn-search {
	left: 15px;
	right: auto;
}
.rtl-mode .header .navbar-user img {
	float: right;
	margin-left: 10px;
	margin-right: 0;
}
.rtl-mode .caret {
	margin-left: 0;
	margin-right: 2px;
}
.rtl-mode .sidebar,
.rtl-mode .sidebar-bg {
	left: auto;
	right: 0;
}
.rtl-mode .sidebar .nav {
	padding: 0;
}
.rtl-mode .sidebar .nav > li > a i,
.rtl-mode .sidebar .nav > li > a .icon-img {
	float: right;
	margin-left: 10px;
	margin-right: -5px;
}
.rtl-mode .sidebar li > a .caret, 
.rtl-mode .float-sub-menu li > a .caret {
	float: left;
}
.rtl-mode .sidebar li > a .caret:before, 
.rtl-mode .float-sub-menu li > a .caret:before {
	content: '\f053';
}
.rtl-mode .sidebar li.active > a .caret:before, 
.rtl-mode .sidebar li.expanding > a .caret:before, 
.rtl-mode .sidebar li.expand > a .caret:before, 
.rtl-mode .float-sub-menu li.active > a .caret:before, 
.rtl-mode .float-sub-menu li.expanding > a .caret:before, 
.rtl-mode .float-sub-menu li.expand > a .caret:before {
	transform: rotate(-90deg);
}
.rtl-mode .sidebar .sub-menu {
	padding-left: 0;
	padding-right: 39px;
}
.rtl-mode .sidebar .sub-menu > li:before {
	left: auto;
	right: -13px;
}
.rtl-mode .sidebar .sub-menu > li:after {
	left: auto;
	right: 0;
}
.rtl-mode .sidebar .sub-menu > li > a {
	padding-left: 20px;
	padding-right: 15px;
}
.rtl-mode .sidebar .sub-menu > li > a:after {
	left: auto;
	right: -11px;
}
.rtl-mode .sidebar-minify-btn {
	float: left;
	border-radius: 20px;
}
.rtl-mode .page-sidebar-minified .sidebar .caret {
	right: auto;
	left: 0px;
}
.rtl-mode .page-with-right-sidebar .sidebar .nav > li > a .badge {
	float: left !important;
}
.rtl-mode .content {
	margin-left: 0;
	margin-right: 220px;
}
.rtl-mode .page-sidebar-minified .content {
    margin-right: 60px;
}
.rtl-mode .sidebar-minify-btn i {
	float: left !important;
}
.rtl-mode .page-sidebar-minified .sidebar-minify-btn i {
	float: none !important;
}
.rtl-mode .float-sub-menu-container {
	left: auto !important;
	right: 60px !important;
}
.rtl-mode .float-sub-menu {
	padding-left: 0;
	padding-right: 39px;
}
.rtl-mode .float-sub-menu .sub-menu {
	padding-left: 0;
	padding-right: 15px;
}
.rtl-mode .float-sub-menu li:before {
	left: auto;
	right: -13px;
}
.rtl-mode .float-sub-menu li:after {
	left: auto;
	right: 0;
}
.rtl-mode .float-sub-menu li > a:after {
	left: auto;
	right: -11px;
}
.rtl-mode .float-sub-menu-arrow {
	right: 0;
	left: auto;
}
.rtl-mode .float-sub-menu-line {
	right: 26px;
}
.rtl-mode .btn-scroll-to-top {
	left: 25px;
	right: auto;
}
.rtl-mode .theme-panel {
	left: -175px;
	right: auto;
	transition: left .2s linear;
}
.rtl-mode .theme-panel.theme-panel-lg {
	left: -260px;
	right: auto;
}
.rtl-mode .theme-panel.active,
.rtl-mode .theme-panel.theme-panel-lg.active {
	left: 0;
	right: auto;
}
.rtl-mode .theme-panel .theme-collapse-btn,
.rtl-mode .theme-panel.theme-panel-lg .theme-collapse-btn {
	left: auto;
	right: -30px;
	border-radius: 0 4px 4px 0;
}
.rtl-mode .theme-panel .theme-list > li {
	float: right;
}
.rtl-mode .theme-panel .theme-list > li + li {
	margin-left: 0;
	margin-right: 5px;
}
.rtl-mode .theme-panel.theme-panel-lg .theme-list > li + li {
	margin-left: 0;
	margin-right: 3px;
}
.rtl-mode .widget-stats .stats-icon.stats-icon-lg {
	right: auto;
	left: 0;
}
.rtl-mode .widget-stats .stats-icon {
	left: 15px;
	right: auto;
	margin-left: 0;
	margin-right: 15px;
}
.rtl-mode .dropdown-menu.media-list > .media .media-left {
	padding-right: 0;
	padding-left: 10px;
}
.rtl-mode .media-list,
.rtl-mode .list-group {
	padding-left: 0;
	padding-right: 0;
}
.rtl-mode .legend > table tr td.legendLabel {
	padding-right: 0 !important;
	padding-left: 10px !important;
}
.rtl-mode .media > .pull-left, 
.rtl-mode .media > .media-left {
	padding-left: 15px;
	padding-right: 0;
}
.rtl-mode .media > .pull-right, 
.rtl-mode .media > .media-right {
	padding-right: 15px;
	padding-left: 0;
}
.rtl-mode .todolist-input {
	border-right: none;
	border-left: 1px solid #ddd;
}
.rtl-mode .input-group > .custom-select:not(:last-child), 
.rtl-mode .input-group > .form-control:not(:last-child) {
	border-radius: 0 .25rem .25rem 0;
}
.rtl-mode .input-group > .custom-select:not(:first-child), 
.rtl-mode .input-group > .form-control:not(:first-child) {
	border-radius: .25rem 0 0 .25rem;
}
.rtl-mode .input-group-addon:last-child, 
.rtl-mode .input-group > .input-group-append > .btn, 
.rtl-mode .input-group > .input-group-append > .input-group-text, 
.rtl-mode .input-group > .input-group-prepend:not(:first-child) > .btn, 
.rtl-mode .input-group > .input-group-prepend:not(:first-child) > .input-group-text, 
.rtl-mode .input-group > .input-group-prepend:first-child > .btn:not(:first-child), 
.rtl-mode .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child),
.rtl-mode .input-group .form-control + .input-group-btn > .btn:last-child  {
	border-radius: .25rem 0 0 .25rem;
}
.rtl-mode .input-group-addon:first-child, 
.rtl-mode .input-group > .input-group-prepend > .btn, 
.rtl-mode .input-group > .input-group-prepend > .input-group-text, 
.rtl-mode .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), 
.rtl-mode .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), 
.rtl-mode .input-group > .input-group-append:not(:last-child) > .btn, 
.rtl-mode .input-group > .input-group-append:not(:last-child) > .input-group-text {
	border-radius: 0 .25rem .25rem 0;
}
.rtl-mode .input-group-append > .btn:first-child {
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
}
.rtl-mode .input-group-prepend > .btn:last-child {
	border-top-right-radius: 0;
	border-bottom-right-radius: 0;
}
.rtl-mode .input-group-append > .btn:first-child:last-child {
	border-radius: .25rem 0 0 .25rem;
}
.rtl-mode .input-group-prepend > .btn:first-child:last-child {
	border-radius: 0 .25rem .25rem 0;
}
.rtl-mode .input-group-append {
	margin-left: 0;
	margin-right: -1px;
}
.rtl-mode .input-group-prepend {
	margin-right: 0;
	margin-left: -1px;
}
.rtl-mode .btn-group .btn + .btn {
	margin-right: -1px;
}
.rtl-mode .btn-group > .btn-group:not(:first-child) > .btn, 
.rtl-mode .btn-group > .btn:not(:first-child) {
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
}
.rtl-mode .btn-group > .btn-group:not(:last-child) > .btn, 
.rtl-mode .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
}
.rtl-mode .btn-group + .btn-group {
	margin-right: 5px;
}
.rtl-mode .inbox .email-to .control-label {
	left: auto;
	right: 0;
	padding-left: 0;
	padding-right: 10px;
}
.rtl-mode .inbox .float-right-link {
	right: auto;
	left: 0;
}
.rtl-mode ul.tagit li {
	float: right;
}
.rtl-mode .inbox .email-to .tagit, 
.rtl-mode .inbox .email-to .form-control {
    padding: 9px 45px 9px 12px !important;
}
.rtl-mode .attached-document > li {
	float: right;
	margin-right: 0;
	margin-left: 15px;
}
.rtl-mode dd {
	margin-right: 0;
}
.rtl-mode .nav {
	padding-right: 0;
}
.rtl-mode .tab-overflow .next-button {
	left: 0;
	right: auto;
}
.rtl-mode .tab-overflow .prev-button {
	right: 0;
	left: auto;
}
.rtl-mode .panel-heading-btn {
	float: left;
}
.rtl-mode .panel-heading-btn > a {
	margin-left: 0;
	margin-right: 8px;
}
.rtl-mode .panel-heading.p-0 .panel-heading-btn {
	margin-left: 10px;
	margin-right: 0 !important;
}
.rtl-mode .close {
	float: left;
	margin-left: 0;
	margin-right: 15px;
}
.rtl-mode .dropdown {
	text-align: right;
}
.rtl-mode .hljs-wrapper {
	direction: ltr;
	text-align: left;
}
.rtl-mode .input-group-append .btn.btn-lime,
.rtl-mode .btn-group .btn.btn-lime:not(.active) + .btn.btn-lime,
.rtl-mode .input-group-btn .btn.btn-lime:not(.active) + .btn.btn-lime {
    border-right-color: #9CCC65;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-lime {
    border-left-color: #9CCC65;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-grey,
.rtl-mode .btn-group .btn.btn-grey:not(.active) + .btn.btn-grey,
.rtl-mode .input-group-btn .btn.btn-grey:not(.active) + .btn.btn-grey {
    border-right-color: $silver_darker;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-grey {
    border-left-color: $silver_darker;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-yellow,
.rtl-mode .btn-group .btn.btn-yellow:not(.active) + .btn.btn-yellow,
.rtl-mode .input-group-btn .btn.btn-yellow:not(.active) + .btn.btn-yellow {
    border-right-color: #FFEE58;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-yellow {
    border-left-color: #FFEE58;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-purple,
.rtl-mode .btn-group .btn.btn-purple:not(.active) + .btn.btn-purple,
.rtl-mode .input-group-btn .btn.btn-purple:not(.active) + .btn.btn-purple {
    border-right-color: #AB47BC;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-purple {
    border-left-color: #AB47BC;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-info,
.rtl-mode .btn-group .btn.btn-info:not(.active) + .btn.btn-info,
.rtl-mode .input-group-btn .btn.btn-info:not(.active) + .btn.btn-info {
    border-right-color: #26C6DA;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-info {
    border-left-color: #26C6DA;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-danger,
.rtl-mode .btn-group .btn.btn-danger:not(.active) + .btn.btn-danger,
.rtl-mode .input-group-btn .btn.btn-danger:not(.active) + .btn.btn-danger {
    border-right-color: #EF5350;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-danger {
    border-left-color: #EF5350;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-warning,
.rtl-mode .btn-group .btn.btn-warning:not(.active) + .btn.btn-warning,
.rtl-mode .input-group-btn .btn.btn-warning:not(.active) + .btn.btn-warning {
    border-right-color: #FFA726;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-warning {
    border-left-color: #FFA726;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-success,
.rtl-mode .btn-group .btn.btn-success:not(.active) + .btn.btn-success,
.rtl-mode .input-group-btn .btn.btn-success:not(.active) + .btn.btn-success {
    border-right-color: #26A69A;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-success {
    border-left-color: #26A69A;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-primary,
.rtl-mode .btn-group .btn.btn-primary:not(.active) + .btn.btn-primary,
.rtl-mode .input-group-btn .btn.btn-primary:not(.active) + .btn.btn-primary {
    border-right-color: #42A5F5;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-primary {
    border-left-color: #42A5F5;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-inverse,
.rtl-mode .btn-group .btn.btn-inverse:not(.active) + .btn.btn-inverse,
.rtl-mode .input-group-btn .btn.btn-inverse:not(.active) + .btn.btn-inverse {
    border-right-color: #424242;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-inverse {
    border-left-color: #424242;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-indigo,
.rtl-mode .btn-group .btn.btn-indigo:not(.active) + .btn.btn-indigo,
.rtl-mode .input-group-btn .btn.btn-indigo:not(.active) + .btn.btn-indigo {
    border-right-color: $indigo_lighter;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-indigo {
    border-left-color: $indigo_lighter;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-green,
.rtl-mode .btn-group .btn.btn-green:not(.active) + .btn.btn-green,
.rtl-mode .input-group-btn .btn.btn-green:not(.active) + .btn.btn-green {
    border-right-color: #66BB6A;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-green {
    border-left-color: #66BB6A;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-pink,
.rtl-mode .btn-group .btn.btn-pink:not(.active) + .btn.btn-pink,
.rtl-mode .input-group-btn .btn.btn-pink:not(.active) + .btn.btn-pink {
    border-right-color: $pink_lighter;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-pink {
    border-left-color: $pink_lighter;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-white,
.rtl-mode .btn-group .btn.btn-white:not(.active) + .btn.btn-white,
.rtl-mode .input-group-btn .btn.btn-white:not(.active) + .btn.btn-white {
    border-right-color: #eee;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-white {
    border-left-color: #eee;
    border-right-color: transparent;
}
.rtl-mode .input-group-append .btn.btn-default,
.rtl-mode .btn-group .btn.btn-default:not(.active) + .btn.btn-default,
.rtl-mode .input-group-btn .btn.btn-default:not(.active) + .btn.btn-default {
    border-right-color: #eee;
    border-left-color: transparent;
}
.rtl-mode .input-group-prepend .btn.btn-default {
    border-left-color: #eee;
    border-right-color: transparent;
}
.rtl-mode .btn-group > .btn + .btn:not(:last-child):not(:first-child):not(.dropdown-toggle) {
	border-radius: 0;
}
.rtl-mode .dropdown-menu {
	text-align: right;
}
.rtl-mode .datepicker {
	direction: rtl;
}
.rtl-mode .list-unstyled {
	padding-right: 0;
}
.rtl-mode .select2-container--default .select2-selection--single .select2-selection__arrow {
	right: auto !important;
	left: 5px !important;
}
.rtl-mode .combobox-container .input-group input {
	border-radius: 0 .25rem .25rem 0 !important;
}
.rtl-mode .bootstrap-select.btn-group .btn .filter-option {
	text-align: right;
}
.rtl-mode .bootstrap-select.btn-group .btn .caret {
	right: auto;
	left: 12px;
}
.rtl-mode .bootstrap-select .dropdown-toggle::after {
	display: none;
}
.rtl-mode .bootstrap-select > .btn {
	padding-left: 25px;
	padding-right: 12px;
}
.rtl-mode .form-check-input {
	margin-left: 0;
	margin-right: -1.25rem;
}
.rtl-mode .form-check {
	padding-left: 0;
	padding-right: 1.25rem;
}
.rtl-mode .form-check-inline .form-check-input {
    margin-left: .3125rem;
    margin-right: 0;
}
.rtl-mode .form-check-inline {
    padding-right: 0;
    margin-left: .75rem;
    margin-right: 0;
}
.rtl-mode .sw-main .sw-toolbar .btn + .btn {
	margin-left: 0;
	margin-right: 10px;
}
.rtl-mode .sw-main .sw-toolbar .sw-btn-next, 
.rtl-mode .sw-main .sw-toolbar .sw-btn-next.disabled, 
.rtl-mode .sw-main .sw-toolbar .sw-btn-next.disabled:hover, 
.rtl-mode .sw-main .sw-toolbar .sw-btn-next.disabled:focus {
	border-color: transparent !important;
}
.rtl-mode .sw-main > ul.step-anchor.nav-tabs > li > a .number {
	float: right;
	margin-right: 0;
	margin-left: 10px;
}
.rtl-mode .sw-main > ul.step-anchor.nav-tabs > li > a .number + .info {
	padding-left: 15px;
	padding-right: 0;
}
.rtl-mode .sw-theme-default .step-content {
	text-align: right;
}
.rtl-mode ul.wysihtml5-toolbar > li {
	float: right;
	margin-right: 0;
	margin-left: 5px;
}
.rtl-mode .dataTables_length, 
.rtl-mode div.dataTables_info {
	float: right;
	margin-right: 0;
	margin-left: 15px;
}
.rtl-mode div.dataTables_wrapper div.dataTables_filter {
	text-align: left;
}
.rtl-mode div.dataTables_wrapper div.dataTables_filter input {
	margin-left: 0;
	margin-right: 0.5em;
}
.rtl-mode .pagination > li:first-child > a {
	margin-left: 5px;
}
.rtl-mode .pagination > li:last-child > a {
	margin-left: 0;
}
.rtl-mode .pagination {
	padding-right: 0;
}
.rtl-mode div.dataTables_wrapper div.dataTables_paginate {
	text-align: left;
}
.rtl-mode .dt-buttons.btn-group {
	float: right;
}
.rtl-mode table.table-bordered.dataTable th:last-child, 
.rtl-mode table.table-bordered.dataTable th:last-child, 
.rtl-mode table.table-bordered.dataTable td:last-child, 
.rtl-mode table.table-bordered.dataTable td:last-child {
	border-right-width: 1px;
}
.rtl-mode table.dataTable thead .sorting:after, 
.rtl-mode table.dataTable thead .sorting_asc:after, 
.rtl-mode table.dataTable thead .sorting_desc:after, 
.rtl-mode table.dataTable thead .sorting_asc_disabled:after, 
.rtl-mode table.dataTable thead .sorting_desc_disabled:after {
	right: auto;
	left: 8px;
}
.rtl-mode table.dataTable thead > tr > th.sorting_asc, 
.rtl-mode table.dataTable thead > tr > th.sorting_desc, 
.rtl-mode table.dataTable thead > tr > th.sorting, 
.rtl-mode table.dataTable thead > tr > td.sorting_asc, 
.rtl-mode table.dataTable thead > tr > td.sorting_desc, 
.rtl-mode table.dataTable thead > tr > td.sorting {
	padding-right: 15px;
	padding-left: 30px;
}
.rtl-mode .DTFC_LeftWrapper {
	display: none;
}
.rtl-mode .fc {
	direction: rtl;
	text-align: right;
}
.rtl-mode .fc-day-grid-event .fc-time {
	float: right;
}
.rtl-mode .map {
	left: 0;
	right: 220px;
}
.rtl-mode .content.content-full-width .breadcrumb {
	margin-left: 20px;
	margin-right: 0;
}
.rtl-mode .map-float-table {
	right: auto;
	left: 25px;
}
.rtl-mode .map .jvectormap-zoomin, 
.rtl-mode .map .jvectormap-zoomout {
	right: 25px !important;
}
.rtl-mode .dropdown-toggle::after {
	margin-left: 0;
	margin-right: .255em;
}
.rtl-mode .footer {
    margin: 0 250px 0 30px;
}
.rtl-mode .page-without-sidebar .content {
	margin-right: 0;
}
.rtl-mode .page-with-wide-sidebar.page-sidebar-minified .content,
.rtl-mode .page-sidebar-minified.page-right-sidebar-collapsed.page-with-two-sidebar .content,
.rtl-mode .page-with-wide-sidebar.page-sidebar-minified.page-right-sidebar-collapsed.page-with-two-sidebar .content {
	margin-right: 60px;
}
.rtl-mode .page-with-two-sidebar .sidebar.sidebar-right, 
.rtl-mode .page-with-two-sidebar .sidebar-bg.sidebar-right {
	left: 0;
	right: auto;
}
.rtl-mode .page-with-two-sidebar .content {
	margin-left: 220px;
}
.rtl-mode .page-with-two-sidebar.page-with-wide-sidebar .content {
	margin-left: 250px;
}
.rtl-mode .page-with-two-sidebar.page-right-sidebar-collapsed .content {
	margin-right: 220px;
	margin-left: 0;
}
.rtl-mode .page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar .content {
	margin-right: 250px;
}
.rtl-mode .page-with-two-sidebar.page-right-sidebar-collapsed .sidebar.sidebar-right,
.rtl-mode .page-with-two-sidebar.page-right-sidebar-collapsed .sidebar-bg.sidebar-right {
	right: auto;
	left: -220px;
}
.rtl-mode .page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar .sidebar.sidebar-right,
.rtl-mode .page-with-two-sidebar.page-right-sidebar-collapsed.page-with-wide-sidebar .sidebar-bg.sidebar-right {
	left: -250px;
}
.rtl-mode input.knob {
	transform: translate(140%, 0);
}
.rtl-mode .page-with-wide-sidebar .content {
    margin-right: 250px;
}
.rtl-mode .top-menu .nav > li > a i {
	margin-right: 0;
	margin-left: 10px;
	float: right;
}
.rtl-mode .top-menu li > a .caret {
	float: left;
}
.rtl-mode .top-menu .nav > li > a .icon-img {
	float: right;
	margin-right: 0;
	margin-left: 7px;
}
.rtl-mode .top-menu .nav > li.menu-control > a i {
	margin: 0;
	float: none;
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-left {
	left: auto;
    right: -50px;
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-left > a {
	box-shadow: -5px 0 8px -3px rgba(0,0,0,0.5);
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-left.show {
    -webkit-animation: slideInRight .2s;
    animation: slideInRight .2s;
	right: 0;
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-right {
	right: auto;
    left: -50px;
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-right > a {
	box-shadow: 5px 0 8px -3px rgba(0,0,0,0.5);
}
.rtl-mode .top-menu .nav > li.menu-control.menu-control-right.show {
    -webkit-animation: slideInLeft .2s;
    animation: slideInLeft .2s;
	left: 0;
}
.rtl-mode .top-menu .nav .sub-menu {
	left: auto;
	right: 0;
}
.rtl-mode.boxed-layout .page-container #header, 
.rtl-mode.boxed-layout .page-container .sidebar, 
.rtl-mode.boxed-layout .page-container .sidebar-bg {
	left: auto;
	right: auto;
}
.rtl-mode .boxed-layout .top-menu, 
.rtl-mode .boxed-layout .pace {
	left: auto;
	margin-left: 0;
    right: 50%;
    margin-right: -540px;
}
.rtl-mode .timeline-header .userimage {
	float: right;
	margin-left: 10px;
	margin-right: 0;
}
.rtl-mode .timeline-likes .stats-right {
	float: left;
}
.rtl-mode .timeline-likes .stats-icon {
	float: right;
}
.rtl-mode .timeline-likes .stats-text {
	float: right;
}
.rtl-mode .timeline-likes .stats-text + .stats-text {
	margin-left: 0;
	margin-right: 15px;
}
.rtl-mode .timeline-likes .stats-icon {
	margin-left: 5px;
}
.rtl-mode .timeline-likes .stats-icon + .stats-icon {
	margin-right: -2px;
	margin-left: 5px;
}
.rtl-mode .countdown-section {
	float: right;
}
.rtl-mode .coming-soon .timer .is-countdown .countdown-section + .countdown-section {
	border-left: none;
    border-right: 1px solid rgba(255,255,255,0.2);
}
.rtl-mode .profile-header-img {
	float: right;
}
.rtl-mode .profile-header-img + .profile-header-info {
	margin-left: 0;
	margin-right: 140px;
}
.rtl-mode .profile-header .profile-header-tab {
	padding-left: 0;
	padding-right:140px;
}
.rtl-mode .login .login-header {
    left: auto;
    right: 50%;
    margin-right: -225px;
}
.rtl-mode .login .login-header .icon {
	right: auto;
	left: 40px;
}
.rtl-mode .checkbox.checkbox-css label,
.rtl-mode .radio.radio-css label {
	padding-left: 0;
	padding-right: 24px;
}
.rtl-mode .checkbox.checkbox-css label:before,
.rtl-mode .checkbox.checkbox-css input:checked + label:after,
.rtl-mode .radio.radio-css label:before,
.rtl-mode .radio.radio-css input:checked + label:after {
	left: auto;
	right: 0;
}
.rtl-mode .radio.radio-css input:checked + label:after {
	right: 5px;
}
.rtl-mode .login.login-v2 {
	margin-left: 0;
	margin-right: -200px;
    right: 50%;
    left: auto;
}
.rtl-mode .login.login-v2 .login-header {
	left: 0;
	right: 0;
	margin: 0;
}
.rtl-mode .login .login-header .brand .logo {
	margin-left: 10px;
	margin-right: 0;
}
.rtl-mode .login.login-v2 .login-header .icon {
	left: 20px;
	right: auto;
}
.rtl-mode .login-bg-list {
	right: auto;
	left: 20px;
}
.rtl-mode .login.login-with-news-feed .news-feed, 
.rtl-mode .register.register-with-news-feed .news-feed {
	left: 500px;
	right: 0;
}
.rtl-mode .login.login-with-news-feed .right-content,
.rtl-mode .register.register-with-news-feed .right-content {
	float: left;
}
.rtl-mode .login.login-with-news-feed .login-header, 
.rtl-mode .register.register-with-news-feed .register-header {
	left: 0;
	right: 0;
	margin: 0;
}
.rtl-mode .navbar-language .flag-icon {
	float: right;
	margin-right: 0;
	margin-left: 7px;
}
.rtl-mode .widget-list-item .widget-list-media + div, 
.rtl-mode .widget-list-item .widget-list-content + div, 
.rtl-mode .widget-list-item .widget-list-action + div {
	padding-left: 15px;
	padding-right: 0;
}
.rtl-mode .widget-todolist-item .widget-todolist-input + div {
	padding-left: 15px;
	padding-right: 0;
}
.rtl-mode .list-email .email-title + .email-desc:before {
	display: none;
}