/* Plugin - Bootstrap Colorpalette */

.bootstrap-colorpalette .btn-color {
	border-radius: 4px;
	width: 19px !important;
	height: 19px !important;
	
	&:focus {
		outline: none !important;
		border: 1px solid $black_darker !important;
	}
}