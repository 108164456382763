/* Component - Checkbox Switcher */

.switcher {
	display: inline-block;
}
.switcher label {
	display: block;
	margin: 0;
	width: 46px;
	height: 28px;
	position: relative;
}
.switcher input {
	display: none;
}
.switcher label:before {
	content: '';
	display: block;
	width: 46px;
	height: 28px;
	background: $grey_lighter;
	border: 1px solid $grey_lighter;
	-webkit-border-radius: 46px;
	   -moz-border-radius: 46px;
	        border-radius: 46px;
    -webkit-transition: all .2s linear;
       -moz-transition: all .2s linear;
        -ms-transition: all .2s linear;
         -o-transition: all .2s linear;
            transition: all .2s linear;
}
.switcher label:after {
	content: '';
	height: 24px;
	width: 24px;
	position: absolute;
	left: 2px;
	top: 2px;
	-webkit-border-radius: 24px;
	   -moz-border-radius: 24px;
	        border-radius: 24px;
	background: #fff;
	-webkit-box-shadow: 0 2px 2px rgba(0,0,0,0.25);
	        box-shadow: 0 2px 2px rgba(0,0,0,0.25);
    -webkit-transition: all .1s linear;
       -moz-transition: all .1s linear;
        -ms-transition: all .1s linear;
         -o-transition: all .1s linear;
            transition: all .1s linear;
}
.switcher input:checked + label:before {
	content: '';
	background: $blue;
	border: 2px solid $blue;
}
.switcher.switcher-success input:checked + label:before {
	background: $teal;
	border-color: $teal;
}
.switcher.switcher-green input:checked + label:before {
	background: #4CAF50;
	border-color: #4CAF50;
}
.switcher.switcher-lime input:checked + label:before {
	background: $lime;
	border-color: $lime;
}
.switcher.switcher-indigo input:checked + label:before {
	background: $indigo;
	border-color: $indigo;
}
.switcher.switcher-purple input:checked + label:before {
	background: $purple;
	border-color: $purple;
}
.switcher.switcher-inverse input:checked + label:before {
	background: $black;
	border-color: $black;
}
.switcher.switcher-warning input:checked + label:before {
	background: $orange;
	border-color: $orange;
}
.switcher.switcher-danger input:checked + label:before {
	background: $red;
	border-color: $red;
}
.switcher.switcher-info input:checked + label:before {
	background: #03A9F4;
	border-color: #03A9F4;
}
.switcher.switcher-pink input:checked + label:before {
	background: $pink;
	border-color: $pink;
}
.switcher.switcher-yellow input:checked + label:before {
	background: $yellow;
	border-color: $yellow;
}
.switcher input:checked + label:after {
	left: 20px;
}