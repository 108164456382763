/* Option - Fixed Header */

.page-header-fixed {
    padding-top: 60px;
    
    & #header {
		position: fixed;
	}
	&.has-scroll #header {
		box-shadow: 0 0 24px rgba(0,0,0,0.15);
	}
}