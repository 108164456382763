/* Page Top Menu */

.page-with-top-menu {
    padding-top: 106px;
    
    & .sidebar {
		padding-top: 106px;
	}
}
.top-menu {
    background: #37474F;
    height: 46px;
    position: fixed;
    top: 60px;
    left: 0;
    right: 0;
    z-index: 1015;
    
	& .nav {
		white-space: nowrap;
		display: block;
		
		& > li {
			display: inline-block;
			position: relative;
			
			& + li {
				margin-left: -3px;
			}
			& > a {
				padding: 13px 20px;
				color: #B0BEC5;
				display: block;
				font-weight: 500;
				line-height: 20px;
				text-decoration: none;
				
				& .badge,
				& .label {
					margin-left: 5px;
				}
				& .badge {
					background: #1b1f24;
				}
				& .icon-img {
					float: left;
					margin-right: 7px;
					width: 20px;
					height: 20px;
					margin-left: -3px;
					overflow: hidden;
					border-radius: 4px;
				}
				& .icon-img img {
					max-width: 100%;
					max-height: 100%;
					display: block;
				}
				& .badge.pull-right,
				& .label.pull-right {
					margin-top: 1px;
				}
				& i {
					float: left;
					font-size: 24px;
					margin-right: 10px;
					margin-top: -2px;
					margin-bottom: -2px;
					line-height: 24px;
					width: 24px;
					text-align: center;
					color: #546E7A;
				}
				& .caret.pull-right {
					margin-top: 8px;
					margin-left: 5px;
				}
			}
			&.active > a .label.label-theme,
			&.active > a .badge {
				background: rgba(0,0,0,0.4);
			}
			&.active > a i {
				color: #fff;
			}
			&:hover > a,
			&:focus > a,
			& > a:hover,
			& > a:focus {
				background: none;
				color: #fff;
			}

		}
	}
	& li > a .caret {
		display: block;
		float: right;
		width: 20px;
		height: 20px;
		line-height: 20px;
		text-align: center;
		font-size: 16px;
		border: none;
	}
	& li > a .caret:before {
		content: '\f0d7';
		display: block;
		text-align: center;
		transition: all .2s linear;
		opacity: 0.5;
	}
}


/* Page Top Menu - Sub Menu */

.top-menu {
	& .nav .sub-menu {
		display: none;
		position: absolute;
		left: 0;
		width: 220px;
		top: 100%;
		background: #2F3C43;
		list-style-type: none;
		margin: 0;
		padding: 10px 0;
	}
	& .sub-menu > li > a {
		padding: 5px 20px;
		display: block;
		color: rgba(255, 255, 255, 0.5);
		text-decoration: none;
		position: relative;
	}
	& .nav .sub-menu > li > a .caret {
		margin-top: -1px;
	}
	& .sub-menu .sub-menu {
		background: rgba(0,0,0,0.2);
		position: relative;
		left: 0;
		top: 0;
	}
	& .sub-menu > li > a:hover,
	& .sub-menu > li > a:focus,
	& .sub-menu > li.active > a,
	& .sub-menu > li.active > a:hover,
	& .sub-menu > li.active > a:focus {
		color: #fff;
	}
	& .nav > li.has-sub:hover > .sub-menu {
		display: block;
	}
	& .nav > li.active > a,
	& .nav > li.active > a:hover,
	& .nav > li.active > a:focus{
		background: #222;
		color: #fff;
	}
}


/* Page Top Menu - Left & Right Control */

.top-menu .nav > li {
	&.menu-control {
		position: absolute;
		top: 0;
		width: 46px;
		background: #37474F;
		height: 46px;
		margin: 0;
		
		& > a {
			padding: 0;
			text-align: center;
			line-height: 46px;
			
			& i {
				float: none;
				margin: 0;
				font-size: 24px;
				display: block;
				line-height: 46px;
				color: #fff;
				width: auto;
			}
		}
	}
	&.menu-control.menu-control-left {
		left: -50px;
	}
	&.menu-control.menu-control-right {
		right: -50px;
	}
	&.menu-control.menu-control-left.show {
		-webkit-animation: slideInLeft .2s;
		animation: slideInLeft .2s;
		left: 0;
	}
	&.menu-control.menu-control-right.show {
		-webkit-animation: slideInRight .2s;
		animation: slideInRight .2s;
		right: 0;
	}
	&.menu-control.menu-control-left > a {
		box-shadow: 5px 0 8px -3px rgba(0,0,0,0.5);
	}
	&.menu-control.menu-control-right > a {
		box-shadow: -5px 0 8px -3px rgba(0,0,0,0.5);
	}
}
@keyframes slideInLeft {
	from { left: -50px; }
	to { left: 0; }
}
@-webkit-keyframes slideInLeft {
	from { left: -50px; }
	to { left: 0; }
}
@keyframes slideInRight {
	from { right: -50px; }
	to { right: 0; }
}
@-webkit-keyframes slideInRight {
	from { right: -50px; }
	to { right: 0; }
}